import { useNextContextConsumer } from 'contexts/NextContext';
import ProductCardContainerRenderer from './ProductCardContainerRenderer';
import { productsState } from 'state';
import { useRecoilValueLoadable } from 'recoil';
import { LoaderRing } from 'components/common/loaders/SpinnerLoader';
import { Stack } from '@newulife/common';

export default function ProductSubscriptions({ cardText }) {
  const {state, contents: products} = useRecoilValueLoadable(productsState)
  const { error, setError } = useNextContextConsumer();

  if (state === 'loading') {
    return (
      <Stack mt={4} position="relative">
        <LoaderRing active />
      </Stack>
    )
  }
  if (state === 'isError') setError({ ...error, enabled: true })
  
  return (
    <div
      data-testid="product-list"
      className="grid tab-card-small max-width-700"
    >
      {products?.map((product, index) => (
        <ProductCardContainerRenderer
          data={product}
          cardText={cardText}
          key={index}
        />
      ))}
    </div>
  );
}
