import { useState, memo, useEffect } from 'react';
import ShadowCard from 'components/common/cards/ShadowCard';
import { Typography, TextField, Stack, Button, Box, Alert } from '@newulife/common';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { useField } from 'hooks/useContentfulApi';

const ApplyPromotionCodes = ({ onUpdateCart }) => {
  const [promotionCodeRef] = useField('banner6Ref.fields');
  const { PromoCodeEvaluations } = useRecoilValue(formState);
  const evaluatedPromoCode = PromoCodeEvaluations?.Evaluations?.[0];
  const [promoCode, setPromoCode] = useState('');
  const isAlreadyApplied = evaluatedPromoCode?.PromotionCode === promoCode;
  const isApplyDisabled = !promoCode.length || isAlreadyApplied;
  
  const handleApplyPromoCode = async () => {
    onUpdateCart({ promoCode });
    setPromoCode('');
  };

  const handleRemoveCode = async () => {
    onUpdateCart({ removedPromoCode: evaluatedPromoCode.PromotionCode });
  }

  return (
    <ShadowCard title={promotionCodeRef.text1}>
      <Stack
        py={2}
        justifyItems="flex-start"
        gap={2}
      >
        {!evaluatedPromoCode && (
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className="form-max"
              sx={{
                width: '100%',
                display: 'grid',
                gap: {
                  xs: '20px',
                  md: '30px',
                },
                justifyContent: 'center',
                gridTemplateColumns: '1fr auto',
              }}
            >
                <TextField
                  label={promotionCodeRef.text2}
                  sx={{
                    '.MuiFormLabel-root': {
                      fontSize: 20,
                      lineHeight: '1.1375em',
                      opacity: .8,
                      fontWeight: 500,
                    },
                    '.MuiOutlinedInput-notchedOutline legend': {
                      fontSize: '0.925em',
                    },
                  }}
                  value={promoCode}
                  onChange={(e) => {
                    setPromoCode(e.target.value);
                  }}
                  onKeyPress={(e) => e.key === 'Enter' && !isApplyDisabled && handleApplyPromoCode()}
                />
              <Button
                data-testid="promo-code-button"
                variant="text"
                color="secondary"
                sx={{
                  padding: {
                    xs: '15px 0',
                    md: '10px 0',
                  },
                  height: '100%',
                  '&:hover': {
                    color: '#85BE00',
                  },
                  '&:focus,&:active': {
                    color: '#85BE00',
                    background: 'transparent',
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26) !important',
                    background: 'transparent !important',
                  },
                  ' .small-loader div': {
                    borderTopColor: '#7ab800',
                  },
                }}
                disabled={isApplyDisabled}
                onClick={handleApplyPromoCode}
              >
                {promotionCodeRef.text4}
              </Button>
            </Box>  
          </Stack>
        )}
        {!!evaluatedPromoCode?.PromotionCode && (
          <Stack gap={1.5}>
            <Stack
              key={`code_${evaluatedPromoCode.PromotionCode}`}
              direction="row"
              justifyContent="space-between"
              gap={2}
              className="form-max"
            >
              <Stack>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: '800'
                  }}
                >
                  {evaluatedPromoCode.PromotionCode}
                </Typography>
              </Stack>
              <Button
                variant="text"
                sx={{
                  '&:hover': {
                    color: '#007EC3',
                  },
                  '&:focus,&:active': {
                    color: '#007EC3',
                    background: 'transparent',
                  },
                }}
                onClick={handleRemoveCode}
              >
                {promotionCodeRef.text5}
              </Button>
            </Stack>
            {evaluatedPromoCode.Qualified ? (
              <Typography
                className="form-max"
                variant="body2"
                color="secondary"
                sx={{
                  margin: 'auto',
                }}
              >
                {promotionCodeRef.text6}
              </Typography>
            ) : !PromoCodeEvaluations.IsSuccessful ? (
              <Alert
                className="form-max"
                severity="error"
                sx={{
                  fontSize: 16,
                  lineHeight: '1.125em',
                }}
              >
                {promotionCodeRef.text8}
              </Alert>
            ) : (
              <Alert
                className="form-max"
                severity="warning"
                sx={{
                  fontSize: 16,
                  lineHeight: '1.125em',
                }}
              >
                {promotionCodeRef.text9}
              </Alert>
            )}
          </Stack>
        )}
      </Stack>
    </ShadowCard>
  );
}

export default memo(ApplyPromotionCodes);