import { Typography, Stack } from '@newulife/common'
import Address from 'components/common/Address';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { inputState } from 'state';
import { useField } from 'hooks/useContentfulApi';

export default function Shipping({ toggleEdit: toggleEditFromProps }) {
  const [data] = useField('banner1Ref');
  const navigate = useNavigate();
  const { search } = useLocation();
  const toggleEdit =
    typeof toggleEditFromProps === 'function'
      ? toggleEditFromProps
      : () => {
        navigate(`/steps/create-account#address-form${search}`);
      };

  const { item4 = '', item5 = '' } = data.fields;

  return (
    <CurrentAddress title={item4} buttonTitle={item5} toggleEdit={toggleEdit} />
  );
}

export const CurrentAddress = ({ title }) => {
  const inputs = useRecoilValue(inputState);
  const { NAME_FIRST, NAME_LAST } = inputs;
  const fullName = `${NAME_FIRST} ${NAME_LAST}`;

  return (
    <Stack
      id="current-address"
      mb={4}
    >
      <Typography
        variant="body1"
        mb={1.5}
      >
        {title}
      </Typography>
      <Typography
        variant="body3"
        mb={.5}
      >
        {fullName}
      </Typography>
      <Address address={getCurrentAddress(inputs)} />
    </Stack>
  );
};

function getCurrentAddress(inputs) {
  const shipping = Object.keys(inputs)
    .filter((input) => input.includes('SHIPPING'))
    .reduce((acc, inputKey) => {
      const key = inputKey.split('_')[1] ? inputKey.split('_')[1] : inputKey;
      return { ...acc, [key]: inputs[inputKey] };
    }, {});
  const account = Object.keys(inputs)
    .filter((input) => input.includes('ACCOUNT'))
    .reduce((acc, inputKey) => {
      const key = inputKey.split('_')[1] ? inputKey.split('_')[1] : inputKey;
      return { ...acc, [key]: inputs[inputKey] };
    }, {});
  return Object.keys(shipping).length < 2 ? account : shipping;
}
