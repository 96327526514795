import { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { Typography, Stack, Link, Box, Divider } from '@newulife/common'
import CartOrderCell from 'components/common/cart/CartOrderCell';
import { useProducts, ProductContextProvider } from 'contexts/ProductContext';
import ProductOrderStarterKitModal from './ProductOrderStarterKitModal';
import { useField } from 'hooks/useContentfulApi';

export default function ProductOrderStarterKitSection({
  starterKit,
  onUpdateCart,
}) {
  const [data] = useField('banner1Ref');
  const { dispatch } = useProducts();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasDispatched, setHasDispatched] = useState(false);

  useEffect(() => {
    if (hasDispatched) {
      console.log('ProductOrderStarterKitSection -> useEffect -> hasDispatched');
      updateCart();
    }
  }, [hasDispatched]);

  if (isNull(starterKit)) {
    return null;
  }

  const save = (starterKit) => {
    if (starterKit) {
      setLoading(true);
      dispatch({
        type: 'addStarterKit',
        product: starterKit,
        quantity: 1,
      });
      setHasDispatched(true);
    } else {
      close();
    }
  };

  const updateCart = async () => {
    await onUpdateCart();
    setLoading(false);
    setHasDispatched(false);
    close();
  };

  const close = () => {
    setModalOpen(false);
  };

  const host = 'https://d9b54x484lq62.cloudfront.net/newulife/shopping/images/';
  const { item7, item8 } = data.fields;
  const { SmallImageURL, DisplayName, Price, Volume, Volume2 } = starterKit;

  return (
    <>
      <Stack>
        <Stack
          data-testid="starterkit-section"
          display="grid"
          gridAutoFlow="column"
          alignItems="baseline"
          justifyContent="flex-start"
          gap={2}
          mb={1}
        >
          <Typography
            variant="body1"
          >
            <strong>{item7}</strong>
          </Typography>
          <Link
            datatestid="edit-starterkit"
            underline="hover"
            sx={{
              cursor: 'pointer',
              fontWeight: 400,
            }}
            onClick={() => setModalOpen(true)}
          >
            {item8}
          </Link>
        </Stack>
        <Divider light />
      </Stack>
      <Box
        mb={4}
      >
        <CartOrderCell
          imgSrc={`${SmallImageURL?.startsWith('https') ? '' : host}${SmallImageURL}`}
          imgAlt={DisplayName}
          title={DisplayName}
          price={Price}
          cv={Volume}
          qv={Volume2}
        />
      </Box>
      {modalOpen && (
        <ProductContextProvider>
          <ProductOrderStarterKitModal
            starterKit={starterKit}
            loading={loading}
            save={save}
            close={close}
          />
        </ProductContextProvider>
      )}
    </>
  );
}
