import { Fragment, useEffect, useState } from 'react';
import { differenceBy } from 'lodash';
import { gtmCaptureReplaceCart } from 'api/gtm';
import { Typography, Stack, Divider, Link } from '@newulife/common'
import CartOrderCell from 'components/common/cart/CartOrderCell';
import CartOrderHeader from 'components/common/cart/CartOrderHeader';
import { useProducts, ProductContextProvider } from 'contexts/ProductContext';
import { SubscriptionContextProvider } from 'contexts/SubscriptionContext';
import ProductOrderProductModal from './ProductOrderProductModal';
import { formState } from 'state';
import { useRecoilValue } from 'recoil';
import { useField } from 'hooks/useContentfulApi';

export default function ProductOrderProductSection({
  products,
  onUpdateCart,
}) {
  const [data] = useField('banner1Ref');
  const { countryInfo: { marketName } } = useRecoilValue(formState);
  const { dispatch } = useProducts();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasDispatched, setHasDispatched] = useState(false);

  useEffect(() => {
    if (hasDispatched) {
      console.log('ProductOrderProductSection -> useEffect -> hasDispatched');
      updateCart();
    }
  }, [hasDispatched]);

  const save = async (newProducts) => {
    if (newProducts) {
      setLoading(true);

      // GTM Analytics
      const data = differenceBy(products, newProducts, 'ProductID');

      if (data.length !== 0) {
        const variant = 'Product';
        const { pathname } = window.location;

        gtmCaptureReplaceCart({ data, marketName, variant, pathname });
      }

      // Update Context
      dispatch({
        type: 'replaceProducts',
        products: newProducts,
      });
      setHasDispatched(true);
    } else {
      close();
    }
  };

  const updateCart = async () => {
    // Update
    await onUpdateCart();

    // Cleanup
    setLoading(false);
    setHasDispatched(false);

    // Close
    close();
  };

  const close = () => {
    setModalOpen(false);
  };

  if (!loading && products.length === 0) {
    return null;
  }

  const { item9, item8, item10, item11, item12 } = data.fields;

  return (
    <>
      <Stack>
        <Stack
          data-testid="products-section"
          display="grid"
          gridAutoFlow="column"
          alignItems="baseline"
          justifyContent="flex-start"
          gap={2}
          mb={1}
        >
          <Typography
            variant="body1"
          >
            <strong>{item9}</strong>
          </Typography>
          <Link
            datatestid="edit-product"
            underline="hover"
            sx={{
              cursor: 'pointer',
              fontWeight: 400,
            }}
            onClick={() => setModalOpen(true)}
          >
            {item8}
          </Link>
        </Stack>
        <Divider light />
      </Stack>
      <CartOrderHeader
        productText={item10}
        qtyText={item11}
        unitPriceText={item12}
      />
      {products.map((product, index) => (
        <Fragment
          key={index}
        >
          <CartOrderCell
            imgSrc={product.SmallImageURL}
            imgAlt={product.DisplayName}
            title={product.DisplayName}
            price={product.WholesalePrice}
            cv={product.Volume}
            qv={product.Volume2}
            qty={product.Quantity}
          />
          {index !== products.length - 1 && <Divider light />}
        </Fragment>
      ))}
      {modalOpen && (
        <ProductContextProvider>
          <SubscriptionContextProvider>
            <ProductOrderProductModal
              products={products}
              loading={loading}
              save={save}
              close={close}
            />
          </SubscriptionContextProvider>
        </ProductContextProvider>
      )}
    </>
  );
}
