import { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import { gtmCaptureRemoveFromCart } from 'api/gtm';
import ProductCard from 'components/common/cards/ProductCard';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import { parseLowestMaxQty } from 'helpers/parse';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function ProductCardContainer({
  data,
  subscribeText,
  initialOrderText,
  autoSubscribe = false,
  disableSubscribe = false,
  subscription = false,
}) {
  const { countryInfo: { marketName } } = useRecoilValue(formState)
  const { dispatch: productDispatch, hasProductInCart } = useProducts();
  const { dispatch: subscriptionDispatch, hasSubscriptionInCart } =
    useSubscriptions();
  const [qty, setQty] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(autoSubscribe);
  const [isAddedToInitialOrder, setIsAddedToInitialOrder] = useState(false);

  const {
    ProductID,
    SignupOptionalItemQtyOptions,
    SignupAutoshipOptionQtyOptions,
    SmallImageURL,
    DisplayName,
    Volume,
    Volume2,
    WholesalePrice,
  } = data;
  const maxQty = parseLowestMaxQty(
    SignupOptionalItemQtyOptions,
    SignupAutoshipOptionQtyOptions,
  );

  useEffect(() => {
    const productResult = hasProductInCart(ProductID);
    const subscriptionResult = hasSubscriptionInCart(ProductID);

    if (productResult && subscriptionResult) {
      // TODO: Figure out which result to take for quantity.
      setQty(subscription ? subscriptionResult.Quantity : productResult.Quantity);

      setIsSubscribed(true);
      setIsAddedToInitialOrder(true);
    } else if (productResult) {
      setQty(productResult.Quantity);
    } else if (subscriptionResult) {
      setQty(subscriptionResult.Quantity);
      setIsSubscribed(true);
    }
  }, [subscription]);

  const decrement = () => {
    const { pathname } = window.location;
    const isSelectProductStep =
      pathname === '/steps/starter-kit/select-product';

    // Check if product is not a subscription.
    if (!isSubscribed) {
      // Remove or decrement based on quantity.
      if (qty == 1) {
        // Run only on this step.
        if (isSelectProductStep) {
          // GTM Analytics
          const variant = 'Product';
          gtmCaptureRemoveFromCart({ data, marketName, variant, pathname });
        }

        removeProductFromCart();
      } else {
        decrementProduct();
      }
    } else {
      // Remove or decrement based on quantity.
      if (qty == 1) {
        // Run only on this step.
        if (isSelectProductStep) {
          // GTM Analytics
          const variant = 'Subscription';
          gtmCaptureRemoveFromCart({ data, marketName, variant, pathname });
        }

        removeSubscriptionFromCart();

        if (isAddedToInitialOrder) {
          removeProductFromCart();
        }

        if (!autoSubscribe) {
          setIsSubscribed(false);
        }
      } else {
        decrementSubscription();
        if (isAddedToInitialOrder) {
          decrementProduct();
        }
      }
    }
    setQty(qty - 1);
  };

  const increment = () => {
    // Check if product is not a subscription.
    if (!isSubscribed) {
      // Add or increment based on quantity.
      if (qty == 0) {
        // Validate product does not already exist in cart.
        const result = hasProductInCart(ProductID);
        if (!result) {
          addProductToCart(1);
        }
      } else {
        incrementProduct();
      }
    } else {
      // Add or increment based on quantity.
      if (qty == 0) {
        addSubscriptionToCart(1);
      } else {
        incrementSubscription();
        if (isAddedToInitialOrder) {
          incrementProduct();
        }
      }
    }
    setQty(qty + 1);
  };

  const validateIncrement = () => {
    if (!isNull(maxQty)) {
      if (qty < maxQty) {
        increment();
      }
    } else {
      increment();
    }
  };

  const toggleSubscription = () => {
    // Increment qty to 1 if subscribe switch is toggled with 0 qty.
    if (!isSubscribed && qty == 0) {
      addSubscriptionToCart(1);
      setQty(1);
    } else if (!isSubscribed && qty > 0) {
      // Remove from products cart and copy to subscriptions cart.
      removeProductFromCart();
      addSubscriptionToCart(qty);
    } else if (isSubscribed && qty > 0) {
      // Remove from subscriptions cart and copy to products cart.
      removeSubscriptionFromCart();

      // If it was not already added to the products cart, add it.
      if (!isAddedToInitialOrder) {
        addProductToCart(qty);
      }
    }

    setIsSubscribed(!isSubscribed);

    if (isAddedToInitialOrder) {
      setIsAddedToInitialOrder(!isAddedToInitialOrder);
    }
  };

  const toggleAdditionToInitialOrder = () => {
    // Check if product has been added to cart.
    if (!isAddedToInitialOrder) {
      // Add to products cart.
      addProductToCart(qty);
    } else {
      // Remove from products cart.
      removeProductFromCart();
    }

    setIsAddedToInitialOrder(!isAddedToInitialOrder);
  };

  const decrementProduct = () => {
    productDispatch({ type: 'decrementProduct', id: ProductID });
  };

  const incrementProduct = () => {
    productDispatch({ type: 'incrementProduct', id: ProductID });
  };

  const removeProductFromCart = () => {
    productDispatch({
      type: 'removeProductFromCart',
      id: ProductID,
    });
  };

  const addProductToCart = (quantity) => {
    productDispatch({
      type: 'addProductToCart',
      product: data,
      quantity,
    });
  };

  const decrementSubscription = () => {
    subscriptionDispatch({ type: 'decrementSubscription', id: ProductID });
  };

  const incrementSubscription = () => {
    subscriptionDispatch({ type: 'incrementSubscription', id: ProductID });
  };

  const removeSubscriptionFromCart = () => {
    subscriptionDispatch({
      type: 'removeSubscriptionFromCart',
      id: ProductID,
    });
  };

  const addSubscriptionToCart = (quantity) => {
    subscriptionDispatch({
      type: 'addSubscriptionToCart',
      subscription: data,
      quantity,
    });
  };

  return (
    <ProductCard
      subscribeText={subscribeText}
      initialOrderText={initialOrderText}
      imgSrc={SmallImageURL}
      imgAlt={DisplayName}
      title={DisplayName}
      cv={Volume}
      qv={Volume2}
      price={WholesalePrice}
      qty={qty}
      maxQty={maxQty}
      decrement={decrement}
      increment={validateIncrement}
      disableSubscribe={disableSubscribe}
      isSubscribed={isSubscribed}
      isAddedToInitialOrder={isAddedToInitialOrder}
      toggleSubscription={toggleSubscription}
      toggleAdditionToInitialOrder={toggleAdditionToInitialOrder}
    />
  );
}
