import user from '../api/user';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { formState } from './formState';
import { get as fetch } from 'api/request';
import { getCountries, getMarkets } from 'api/market';
import getLanguages from 'api/getLanguages';
import { repDIDState } from './userState';
import { toast } from 'react-toastify';

const countryValue = selector({
  key: 'country',
  get: ({ get }) => {
    const { countryInfo: { country } = {} } = get(formState);
    return country;
  },
});

const repDIDValue = selector({
  key: 'repDIDValue',
  get: ({ get }) => {
    const { repDID } = get(formState);
    return repDID;
  },
});

const localeValue = selector({
  key: 'locale',
  get: ({ get }) => {
    const { locale } = get(formState);
    return locale;
  },
});
  
const kitsQuery = selector({
  key: 'kitsQuery',
  default: [],
  get: async ({ get }) => {
    const country = get(countryValue);
    const repDID = get(repDIDValue);
    const locale = get(localeValue);
    const RepDID = get(repDIDState)
    if (!country || !locale || (!RepDID && !repDID)) {
      console.warn('could not fetch data', {country, repDID, locale, RepDID})
      return []
    }
    const response = await fetch(
      `/api/getEnrollmentItems/:${country}/:${repDID || RepDID}/:${locale}`,
    );
    if (
      !response.error && typeof response !== 'string' && !response[0]?.Code && response.length > 0
    ) {
      return response;
    } else {
      const error = typeof response === 'string' ? response : response?.error || 'there was an error fetching kits.'
      toast.error(`${error} Please contact support.`, { autoClose: false, toastId: error })
      throw new Error(error)
    }
  },
});

const { persistAtom: persistKits } = recoilPersist({ key: 'NUL_KITS' });
export const kitsState = atom({
  key: 'kits',
  default: kitsQuery,
  effects: [persistKits],
});

const { persistAtom: persistProducts } = recoilPersist({ key: 'NUL_PRODUCTS' });
export const productsState = atom({
  key: 'productsState',
  default: selector({
    key: 'productsQuery',
    get: async ({ get }) => {
      const country = get(countryValue);
      const repDID = get(repDIDValue);
      const locale = get(localeValue);
      const RepDID = get(repDIDState);
      if (!country && !repDID && !locale && !RepDID) {
        throw new Error('Could not fetch data')
      }
      const response = await fetch(`/api/enrollmentGetProducts/:${country}/:${repDID || RepDID}/:${locale}`);
      if (!response.error && typeof response !== 'string') {
        return parseProducts(response);
      } else {
        const error = typeof response === 'string' ? response : response?.error || 'There was an error fetching  products.'
        toast.error(`${error} Please contact support.`, { autoClose: false, toastId: error })
        throw new Error(error)
      }
    },
  }),
  effects: [persistProducts],
});

const { persistAtom: persistMoneyInfo } = recoilPersist({
  key: 'NUL_MONEY_INFO',
});
export const moneyInfoState = atom({
  key: 'moneyInfoState',
  default: selector({
    key: 'moneyInfoQuery',
    get: async ({ get }) => {
      const country = get(countryValue);
      if (!country) return null;
      const response = await user.getMoneyInfo(country);
      if (response.error) throw new Error(response.error)
      if (response.length === 0) {
        console.warn('Error in API call "getMoneyInfo"')
        return null;
      }
      return response[0]
    }
  }),
  effects: [persistMoneyInfo],
});

const { persistAtom: persistMarkets } = recoilPersist({ key: 'NUL_MARKETS' });
export const marketsState = atom({
  key: 'marketState',
  default: selector({
    key: 'marketsQuery',
    get: async ({ get }) => {
      const locale = get(localeValue);
      const response = await getMarkets(locale);
      if (response.error) throw response.error;
      return response;
    },
  }),
  effects: [persistMarkets],
});

const { persistAtom: persistCountries } = recoilPersist({
  key: 'NUL_COUNTRIES',
});
export const countriesState = atom({
  key: 'countriesState',
  default: selector({
    key: 'countriesQuery',
    get: async ({ get }) => {
      const locale = get(localeValue);
      const response = await getCountries(locale);
      if (response.error) throw response.error;
      return response;
    },
  }),
  effects: [persistCountries],
});

const { persistAtom: persistLanguages } = recoilPersist({
  key: 'NUL_LANGUAGES',
});
export const languagesState = atom({
  key: 'languagesState',
  default: selector({
    key: 'languagesQuery',
    get: async () => {
      const response = await getLanguages();
      if (response.error) throw response.error;
      return { languages: response};
    },
  }),
  effects: [persistLanguages],
});

function parseProducts(prodArray) {
  if (!prodArray) return [];

  function reorderItemToTop(id) {
    const indexOfItem = prodArray.findIndex((el) => el.InventoryID === id);
    const item = prodArray.splice(indexOfItem, 1)[0];
    prodArray.unshift(item);
  }
  reorderItemToTop(416); //mobileapp
  reorderItemToTop(412); // somaderm
  reorderItemToTop(425); // somaderm canada
  return prodArray;
}
