import { isEmpty } from 'lodash';
import useContentfulApi from 'hooks/useContentfulApi';
import Image from './Image';
import LanguageSelector from './LanguageSelector';
import LoggedInAvatar from './LoggedInAvatar';
import { NextContextProvider } from 'contexts/NextContext';
import {
  Box,
  Stack,
} from '@newulife/common'
import CONTENTFUL from 'constants/contentful'
import { useRecoilValue, useRecoilState } from 'recoil';
import { formState, marketParamState, repState } from 'state';

export default function Header() {
  const { authRep } = useRecoilValue(formState);
  const data = useContentfulApi(CONTENTFUL.header);
  useContentfulApi(CONTENTFUL.errors, true);
  const { countryInfo } = useRecoilValue(formState);
  const [marketParam, setMarketParam] = useRecoilState(marketParamState);
  const rep = useRecoilValue(repState());
  const contentfulMarket = marketParam?.contentfulWhitelistKey || countryInfo?.name?.replace(' NFR', '') || 'United States'

  if (!data) {
    return null;
  }

  const { text1: loggedInText, image } = data?.fields;

  return (
    <NextContextProvider>
      <Box
        as="nav"
        sx={{
          backgroundColor: '#fff',
          padding: 2,
          zIndex: 100,
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: {
            md: '0 4px 14px rgba(0,0,0,.15)',
          },
        }}
      >
        <Image data={image} />
        <Stack
          direction="row"
          alignItems="center"
        >
          <LanguageSelector />
          {!isEmpty(authRep) && (
            <LoggedInAvatar 
              text={loggedInText} 
              showRepDID={contentfulMarket === 'China' || rep?.shipCountry === 'CHINA'}
            />
          )}
        </Stack>
      </Box>
    </NextContextProvider>
  );
}
