import { isNull } from 'lodash';
import MarketsList from 'constants/markets';

const defaultValue = '';

export function createEnrollData(data) {
  const { form, products, inputs, addresses, account } = data;
  const markets = new MarketsList();
  const {
    localeId, //@TODO set this on locale change
    businessMarket: { name: market, iso2: marketIso } = {},
    countryInfo: { iso2: shipIso } = {},
    locale: languageCode,
    rep: { RepDID: sponsorRepId = '' } = {},
    repPosition: position = '',
    authRep: { uplineRepDID: uplineRepId } = {},
    terms,
  } = form;
  const {
    PASSWORD: password,
    NAME_BANK_JP: bankNameJp,
    ID_BANK: bankNumberJP,
    BANK_ACCOUNT_NAME: PhoneticName,
  } = account;
  const {
    NAME_FIRST: firstName,
    NAME_LAST: lastName,
    COMPANY: company,
    PHONE: phone,
    EMAIL: email,
    PHONE: shipPhone,
    IDENTITY,
    RESIDENT_REGISTRATION_NUMBER,
    NAME_GOVERNMENT,
    GOVERNMENT_ID,
    GOVERNMENT_NAME,
    DOB: birthday,
    ID_BRANCH: branchNumber,
    ID_BANK: bankNumber,
    // PASSWORD: password,
    NAME_BANK: bankName,
    NAME_BRANCH: branchName,
    NAME_ON_BANK: bankAccountName,
    BANK_ACCOUNT: bankAccountNumber,
    BANK_ACCOUNT_TYPE: bankAccountType = '',
    ACCOUNT_ADDRESS1: address1,
    ACCOUNT_ADDRESS2: address2,
    ACCOUNT_ADDRESS3: address3,
    ACCOUNT_ADDRESS4: address4,
    ACCOUNT_ADDRESS5: address5,
    ACCOUNT_CITY: city,
    ACCOUNT_COUNTY: county = '',
    ACCOUNT_STATE: state,
    ACCOUNT_ZIP: zip,
    SHIPPING_ADDRESS1,
    SHIPPING_ADDRESS2,
    SHIPPING_ADDRESS3,
    SHIPPING_ADDRESS4,
    SHIPPING_ADDRESS5,
    SHIPPING_CITY,
    SHIPPING_COUNTY = '',
    SHIPPING_STATE,
    SHIPPING_ZIP,
    miscFields,
    // JP
    JAPAN_SERIAL_NUMBER: JapanSerialNumber,
    NICKNAME: PreferredName,
    ROMAN_CHARACTER_NAME: RomanizedFirstName,
    MY_NUMBER,
  } = inputs;

  const governmentID = RESIDENT_REGISTRATION_NUMBER || IDENTITY || GOVERNMENT_ID || MY_NUMBER;
  const governmentIDName = NAME_GOVERNMENT || GOVERNMENT_NAME;

  const shipAddressParse = {
    shipAddress1: SHIPPING_ADDRESS1 || address1,
    shipAddress2: SHIPPING_ADDRESS2 || address2,
    shipAddress3: SHIPPING_ADDRESS3 || address3,
    shipAddress4: SHIPPING_ADDRESS4 || address4,
    shipAddress5: SHIPPING_ADDRESS5 || address5,
    shipCity: SHIPPING_CITY || city,
    shipCounty: SHIPPING_COUNTY || county,
    shipState: SHIPPING_STATE || state,
    shipZip: SHIPPING_ZIP || zip
  }

  return {
    products,
    uplineRepId,
    position,
    localeId,
    firstName,
    lastName,
    company,
    email,
    password,
    sponsorRepId,
    market,
    languageCode,
    address1,
    address2,
    address3,
    address4,
    address5,
    city,
    state,
    zip,
    phone,
    county,
    country: markets.find(marketIso).country,
    shipCountry: markets.find(shipIso).country,
    addressValidated: addresses?.[0]?.selected === 'serviceobjects',
    shipAddressValidated: addresses?.[1]?.selected === 'serviceobjects',
    shipPhone,
    governmentID,
    governmentIDName,
    //...(marketIso !== 'KR' && { birthday }), - No market seems to be using this
    bankName,
    bankNameJp,
    bankAccountName,
    bankAccountNumber,
    bankNumber,
    bankNumberJP,
    branchNumber,
    branchName,
    bankAccountType,
    ...shipAddressParse,
    terms,
    // JP
    RomanizedFirstName,
    miscFields: marketIso === 'JP' ? { JapanSerialNumber, PhoneticName, PreferredName, ...(miscFields || {}) } : miscFields,
  };
}

export function forceDefaultValues(userData) {
  if (!userData) return;
  // list data you don't need in order the rest will be in 'shipInfo'
  const {
    address1,
    address2,
    address3,
    address4,
    address5,
    city,
    state,
    zip,
    phone,
    password,
    market,
    languageCode,
    addressValidated,
    shipAddressValidated,
    ...shipInfo
  } = userData;

  return {
    ...shipInfo,
    repId: defaultValue,
    orderId: defaultValue,
    shipping: defaultValue,
    subTotal: defaultValue,
    total: defaultValue,
    tax: defaultValue,
    paid: defaultValue,
    cv: defaultValue,
    qv: defaultValue,
    trackingNumber: defaultValue,
    paymentTypeId: defaultValue,
    shipMethod: defaultValue,
    shipProvider: defaultValue,
  };
}

export const parseMaxQty = (qtyOptions) => {
  if (typeof qtyOptions === 'string') {
    const splitArr = qtyOptions.split('-');
    const maxQty = splitArr[splitArr.length - 1];

    if (!isNaN(maxQty)) {
      return parseInt(maxQty);
    }
  }

  return null;
};

export const parseLowestMaxQty = (qtyOptions1, qtyOptions2) => {
  const qty1 = parseMaxQty(qtyOptions1);
  const qty2 = parseMaxQty(qtyOptions2);

  if (isNull(qty1) && isNull(qty2)) return null;
  if (isNull(qty2)) return qty1;
  if (isNull(qty1)) return qty2;
  if (qty1 > qty2) return qty2;
  return qty1;
};

// can be single field or array of fields
export function findInDictionary(dictionary, id, textOnly = true) {
  if (!dictionary || !id || !dictionary.length) {
    return textOnly ? '' : {};
  }
  const [inputObj = {}] = dictionary?.filter((inputObj) => inputObj.id === id);
  if (!inputObj?.text) {
    console.warn(`no label found in dictionary with id - ${id}`);
    return;
  }

  const { text } = inputObj;
  return textOnly ? text : inputObj;
}

export function markupToArray(string) {
  if (!string || typeof string !== 'string') return [];
  return string.split('\n').filter((el) => el);
}

export function getKoreaBirthday(governmentID) {
  // This is only being used for validation right now.

  // Parse out the birthday from the Korea RRN
  const year = governmentID.substring(0, 2)
  const month = governmentID.substring(2, 4)
  const day = governmentID.substring(4, 6)

  // Determine the full birth year
  const currentYear = new Date().getFullYear().toString().slice(-2)
  const fullBirthYear = year > currentYear ? `19${year}` : `20${year}`

  //Validate Month
  if (isNaN(month) || parseInt(month) < 1 || parseInt(month) > 12) {
    return false;
  }

  //Validate Day
  const daysInMonth = new Date(fullBirthYear, month, 0).getDate();
  if (isNaN(day) || parseInt(day) < 1 || parseInt(day) > daysInMonth) {
    return false;
  }

  // Return the birthday in valid format
  return `${fullBirthYear}-${month}-${day}`
}
