import { useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { usePage } from 'hooks/useContentfulApi'
import useQuery from 'hooks/useQuery'
import {
  repState,
  repDIDState,
  guidState,
  uplineRepDIDState,
  repPositionState,
  repIdParamState,
  idParamState,
  marketParamState,
  formState,
  persistRepState,
  inputState,
  accountAddress,
  shippingAddress,
} from 'state'
import Header from 'components/home/Header'
import Sponsor from 'components/home/Sponsor'
import MainContent from 'components/common/layout/MainContent'
import Page from 'components/common/layout/Page'
import { DownlineSearchCard } from 'components/common/cards/SponsorCard'
import { useProducts } from 'contexts/ProductContext'
import { useSubscriptions } from 'contexts/SubscriptionContext'
import MarketsList from 'constants/markets'

const markets = new MarketsList().list

export default function HomePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()

  const [guid, setGuid] = useRecoilState(guidState)
  const [uplineRepDID, setUplineRepDID] = useRecoilState(uplineRepDIDState)
  const [repPosition, setRepPosition] = useRecoilState(repPositionState)
  const [repIdParam, setRepIdParam] = useRecoilState(repIdParamState)
  const [idParam, setIdParam] = useRecoilState(idParamState)
  const [marketParam, setMarketParam] = useRecoilState(marketParamState)
  const rep = useRecoilValue(repState())
  const [repDID, setRepDID] = useRecoilState(repDIDState)

  const resetInputs = useResetRecoilState(inputState)
  const resetAdd1 = useResetRecoilState(accountAddress)
  const resetAdd2 = useResetRecoilState(shippingAddress)
  const resetForm = useResetRecoilState(formState)
  const resetRepDIDState = useResetRecoilState(repDIDState)
  const setPersistRep = useSetRecoilState(persistRepState)

  const { dispatch: productDispatch } = useProducts()
  const { dispatch: subscriptionDispatch } = useSubscriptions()

  const initialQueryParams = useMemo(() => {
    const qGuid = query.get('guid')
    const qUpline = query.get('uplineRepDID')
    const qPosition = query.get('position')
    const qRepId = query.get('repId')
    const qId = query.get('id')
    const qMarket = query.get('market')
    return { qGuid, qUpline, qPosition, qRepId, qId, qMarket }
  }, [query])

  useEffect(() => {
    localStorage.clear()
    resetAdd1()
    resetAdd2()
    resetInputs()
    resetForm()
    resetRepDIDState()
    setPersistRep(true)
    productDispatch({ type: 'clearCart' })
    subscriptionDispatch({ type: 'clearCart' })

    if (initialQueryParams.qGuid) setGuid(initialQueryParams.qGuid)
    if (initialQueryParams.qUpline) setUplineRepDID(initialQueryParams.qUpline)
    if (initialQueryParams.qPosition) setRepPosition(initialQueryParams.qPosition)
    if (initialQueryParams.qRepId) setRepIdParam(initialQueryParams.qRepId)
    if (initialQueryParams.qId) setIdParam(initialQueryParams.qId)

    if (initialQueryParams.qMarket) {
      const foundMarket = markets.find(m => m.iso2 === initialQueryParams.qMarket) || null
      setMarketParam(foundMarket)
    } else if (!marketParam?.contentfulWhitelistKey) {
      const defaultMarket = markets.find(m => m.iso2 === 'US')
      setMarketParam(defaultMarket)
    }

    const repIdToSet = rep?.RepDID || initialQueryParams.qRepId || initialQueryParams.qId
    if (repIdToSet) setRepDID(repIdToSet)

    const paramsToPersist = {}
    if (initialQueryParams.qGuid) paramsToPersist.guid = initialQueryParams.qGuid
    if (initialQueryParams.qUpline) paramsToPersist.uplineRepDID = initialQueryParams.qUpline
    if (initialQueryParams.qPosition) paramsToPersist.position = initialQueryParams.qPosition
    if (initialQueryParams.qRepId) paramsToPersist.repId = initialQueryParams.qRepId
    if (initialQueryParams.qId) paramsToPersist.id = initialQueryParams.qId
    if (marketParam?.iso2) paramsToPersist.market = marketParam.iso2

    const searchParams = new URLSearchParams(paramsToPersist)
    // history.replace({ pathname: location.pathname, search: searchParams.toString() })
    navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` }, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const repIdToSet = rep?.RepDID || repIdParam || idParam
    if (repIdToSet && repIdToSet !== repDID) {
      setRepDID(repIdToSet)
    }
  }, [rep, repIdParam, idParam, repDID, setRepDID])

  const data = usePage('index')

  if (!data) return null

  const { banner1Ref, banner2Ref } = data.fields
  const headerContent = [banner1Ref?.fields?.item1, banner1Ref?.fields?.item2]
  const bannerItems = banner2Ref?.fields.listArray.map(({ fields: { textString } }) => textString) || []
  const [item1, item2, ...cardContent] = bannerItems
  const guidHeaderContent = [item1, item2]

  return (
    <Page className="welcome-screen">
      <MainContent>
        <Header contentful={repDID ? guidHeaderContent : headerContent} />
        {guid ? (
          <DownlineSearchCard contentful={cardContent} />
        ) : (
          <Sponsor data={banner1Ref} isAuthed={!!guid} />
        )}
      </MainContent>
    </Page>
  )
}
