import { useEffect, useState, useRef, createContext, useContext } from 'react';
import { getEntryByLocale, getPageByMarket } from 'api/contentful';
import { useRecoilValue, useRecoilState } from 'recoil';
import { formState, marketParamState, repDIDState, repState } from 'state';
import MarketsList from 'constants/markets';
import { get, find } from 'lodash';

const markets = new MarketsList();

export default function useContentfulApi(entryId, pushToWindow = false) {
  const { locale } = useRecoilValue(formState);
  const [data, setData] = useState();

  useEffect(() => {
    if (entryId) {
      getEntryByLocale(entryId, locale).then((res) => {
        const { error } = res;
        !error && setData(res);
        if (pushToWindow) {
          window.contentfulData = window.contentfulData || {};
          window.contentfulData[entryId] = res;          
        }
      });
    }
  }, [locale, entryId]);

  return data;
}

export function usePage(page) {
  const isLoading = useRef(false)
  const isRepLoading = useRef(false)
  const { locale, localeId, countryInfo } = useRecoilValue(formState);
  const [marketParam, setMarketParam] = useRecoilState(marketParamState);
  const repDid = useRecoilValue(repDIDState);
  const rep = useRecoilValue(repState(repDid));
  const contentfulMarket = 
    (countryInfo?.name ? markets.find(countryInfo?.name?.replace(' NFR', ''))?.contentfulWhitelistKey : null) || 
    marketParam?.contentfulWhitelistKey;
  const [pageData, setPageData] = useState();
  const [repPageData, setRepPageData] = useState();

  useEffect(() => {
    if (page && locale && contentfulMarket && !isLoading.current) {
      isLoading.current = true;
      getPageByMarket(page, locale, contentfulMarket).then(res => {
        isLoading.current = false;
        if (!res) return
        setPageData(res);
      })
    }
  }, [locale, contentfulMarket, page]);

  useEffect(() => {
    if (page && locale && rep?.ShipCountry && !isRepLoading.current) {
      isRepLoading.current = true;
      const market = markets.list.find(({ country }) => country.toUpperCase() === rep?.ShipCountry)?.contentfulWhitelistKey
      getPageByMarket(page, locale, market).then(res => {
        isRepLoading.current = false;
        if (!res) return
        setRepPageData(res);
      })
    }
  }, [locale, rep?.ShipCountry, page]);

    let res = pageData;
    
    if (repPageData && rep?.ShipCountry) {
      if (['index', 'select-kit', 'starter-kit', 'confirmation'].includes(page)) {
        res = repPageData;
      } else if (['create-account', 'review', 'pay'].includes(page) && res?.fields) {
        res.fields.banner1Ref.fields.title = repPageData?.fields?.banner1Ref.fields.title;
      }
      if (page === 'create-account' && res?.fields) {
        res.fields.banner2Ref = repPageData?.fields?.banner2Ref;
      }
    }

   return res;
}

const ContentfulContext = createContext();

export function useField(path = '') {
  const data = useContext(ContentfulContext);
  const ref = get(data, `fields${path ? '.' : ''}${path}`) || {};
  return [ref, (key) => find(ref, { key })?.value];
}

export function ContentfulProvider({ children, page }) {
  const data = usePage(page);
  return (
    <ContentfulContext.Provider value={data}>
      {!!data && children}
    </ContentfulContext.Provider>
  );
}
