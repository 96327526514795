import { useEffect } from 'react';
import { DropDownWrapper } from 'components/common/form/FormSelectDropdown';
import { useAccount } from 'contexts/AccountContext';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import MarketsList from 'constants/markets';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useResetRecoilState,
} from 'recoil';
import {
  formState,
  marketsState,
  countriesState,
  kitsState,
  productsState,
} from 'state';
import { isEmpty } from 'lodash';
import config from 'config';
import { useField } from 'hooks/useContentfulApi';

const marketsThatAutoSelectCountry = new MarketsList()
  .marketsThatAutoSelectCountry;

export default function MarketDropDown({ isShipping }) {
  const [marketLabel] = useField('banner1Ref.fields.item3');
  const [shippingLabel] = useField('banner1Ref.fields.item5');
  const [form, setForm] = useRecoilState(formState);
  const { state: marketQueryState, contents: markets } =
    useRecoilValueLoadable(marketsState);
  const { state: countryQueryState, contents: countries } =
    useRecoilValueLoadable(countriesState);
  const { businessMarket, countryInfo } = form;

  const { setAccount } = useAccount();
  const { dispatch: productDispatch } = useProducts();
  const { dispatch: subscriptionDispatch } = useSubscriptions();

  const whitelistedCountries =
    countryQueryState === 'hasValue'
      ? countries
          .filter(({ iso2 }) => !marketsThatAutoSelectCountry.includes(iso2))
          .map(({ iso2, label }) => {
            if(!config.FEATURE_FLAG_THAILAND && iso2 === 'TH') return null
            return label;
          }).filter(Boolean)
      : 
        [];
  const marketsOptions =
    marketQueryState === 'hasValue' ?
      markets.map(({ iso2, label }) => {
        if(!config.FEATURE_FLAG_THAILAND && iso2 === 'TH') return null
        return label}).filter(Boolean)
      : [];

  const newProps = {
    id: isShipping ? 'market-options-shipping' : 'market-options',
    options: isShipping ? whitelistedCountries : marketsOptions,
    label: isShipping ? shippingLabel : marketLabel,
    value: (isShipping || (countryInfo?.iso2 === "CN" && businessMarket?.iso2 === "HK")) ? countryInfo?.label : businessMarket?.label,
    onChange,
  };

  // handler functions
  const resetkits = useResetRecoilState(kitsState);
  const resetProducts = useResetRecoilState(productsState);
  const clearCart = () => {
    productDispatch({ type: 'clearCart' });
    subscriptionDispatch({ type: 'clearCart' });
    resetkits();
    resetProducts();
  };

  const resetAccount = () => {
    setAccount({});
  };

  function onChange(label) {
    let newFormData = {};
    let data = !isShipping
      ? markets.find(({ label: marketLabel }) => marketLabel === label)
      : countries.find(({ label: countryLabel }) => countryLabel === label);
    // handle updates based on if market or shipping
    if (isShipping) {
      newFormData = { countryInfo: data };
    } else if (!isShipping && data.iso2 === 'CN') {
      const hkMarket = markets.find(({ iso2 }) => iso2 === 'HK');
      newFormData = { businessMarket: hkMarket, countryInfo: data };
    } else {
      newFormData = marketsThatAutoSelectCountry.includes(data?.iso2)
        ? // for these markets we set the country to the same as the market
        { businessMarket: data, countryInfo: data }
        : // for these countries we set the market country then allow them to choose their shipping country
        { businessMarket: data, countryInfo: {} };
    }

    // onChange actions
    clearCart();
    resetAccount();
    setForm({ ...form, ...newFormData });
  }

  // useEffect(() => {
  //   if (!isShipping && marketParam?.name && markets?.length && !businessMarket?.iso2) {
  //     onChange(marketParam?.name);
  //   }
  // }, [marketParam?.name, markets?.length, businessMarket?.iso2, isShipping]);

  if (
    isShipping &&
    (marketsThatAutoSelectCountry.includes(businessMarket?.iso2) ||
      isEmpty(businessMarket) ||
      countryInfo.iso2 === 'CN')
  )
    return null;

  return <DropDownWrapper {...newProps} />;
}
