import CartOrderCell from 'components/common/cart/CartOrderCell';
import CartOrderHeader from 'components/common/cart/CartOrderHeader';
import { Divider } from '@newulife/common'
import { Fragment } from 'react';
import formatImageSrc from 'helpers/formatImageSrc'
import { useField } from 'hooks/useContentfulApi';

export default function ProductOrderPromoSection({ promos = [] }) {
  const [data] = useField('banner1Ref.fields.item31');

  if (promos.length === 0) {
    return null;
  }

  const {
    item10: productText,
    item11: qtyText,
    item12: unitPriceText,
    item31: promoText,
  } = data.fields;

  return (
    <>
      <span data-testid="promos-section" className="table-title">
        <p>{promoText}</p>
      </span>
      <CartOrderHeader
        productText={productText}
        qtyText={qtyText}
        unitPriceText={unitPriceText}
      />
      {promos.map((promo, index) => (
        <Fragment>
          <CartOrderCell
            hasDiscount={true}
            imgSrc={formatImageSrc(promo.SmallImage)}
            imgAlt={promo.Description}
            title={promo.Description}
            price={promo.Price}
            discountedPrice={promo.PromotionPrice}
            qty={promo.TotalQuantity}
            key={index}
          />
          {index !== promos.length - 1 && <Divider light />}
        </Fragment>
      ))}
    </>
  );
}
