import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { isNull } from 'lodash';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useProducts } from 'contexts/ProductContext';
import { parseRichText } from 'helpers/contentful';
import MarketDropDown from './MarketDropDown';
import SelectYourStarterKit from './SelectYourStarterKit';
import { Stack, Box, Typography, InfoButton } from '@newulife/common'
import { formState } from 'state';
import envConfig from 'config';
import { useField } from 'hooks/useContentfulApi';

export default function YourStarterKit() {
  const [data] = useField('banner1Ref');
  const {
    businessMarket: { iso2: marketIso2 } = {},
    countryInfo: { iso2: countryIso2 } = {},
  } = useRecoilValue(formState);
  const { next, setNext } = useNextContextConsumer();
  const { state: { starterKit } = {} } = useProducts();

  useEffect(() => {
    if (!isNull(starterKit) || (marketIso2 === 'JP' && !envConfig.FEATURE_FLAG_JAPAN_ENROLLMENT)) {
      setNext({ ...next, enabled: true });
    } else {
      setNext({ ...next, enabled: false });
    }
  }, [marketIso2, countryIso2, starterKit]);

  const { title, item1, item2, richText } = data?.fields;

  return (
    <>
      <Stack
        className="intro-text"
        textAlign="center"
        mb={4}
      >
        {title && (
          <Typography
            variant="header1"
            mb={2}
            data-testid="title"
          >
            {title}
          </Typography>
        )}
        <Typography
          variant="subhead2"
          sx={{
            maxWidth: 440,
          }}
          data-testid="intro-text"
        >
          {item1}
        </Typography>
      </Stack>
      <section>
        <Stack
          display="grid"
          gap={1}
          gridAutoFlow="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          mb={1}
        >
          <Typography
            variant="body2"
          >
            {item2}
          </Typography>
          <InfoButton
            tooltipText={(
              <Box
                sx={{
                  '*': {
                    color: 'text.inverse',
                    '&:last-child': {
                      mb: 0,
                    },
                  },
                }}
              >
                {parseRichText(richText)}
              </Box>
            )}
            sx={{
              'svg': {
                fontSize: 18,
              },
            }}
          />
        </Stack>
        <div className="form-max">
          <Stack gap={2}>
            <MarketDropDown />
            <MarketDropDown isShipping />
          </Stack>
        </div>
      </section>
      <SelectYourStarterKit data={data} />
    </>
  );
}
