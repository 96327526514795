import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const CA = {
  market: 'CA',
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
        },
      },
    },
  },
  inputs: {
    ADDRESS2: {
      validationChecks: [match(fiftyCharactersMax, 'validation-max-50')],
    },
    STATE: {
      labelRef: 'PROVINCE',
    },
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
  },
};
export default CA;
