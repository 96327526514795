import { useEffect } from 'react';
import SaveModal from 'components/common/modals/SaveModal';
import { useProducts } from 'contexts/ProductContext';
import StarterKits from '../starter-kit/select-kit/StarterKits';
import { useRecoilValue } from 'recoil';
import { kitsState } from 'state';
import { useField } from 'hooks/useContentfulApi';

export default function ProductOrderStarterKitModal({
  starterKit,
  loading,
  save,
  close,
}) {
  const [data] = useField('banner2Ref');
  const kits = useRecoilValue(kitsState)
  const { state, dispatch } = useProducts();

  useEffect(() => {
    if (starterKit) {
      dispatch({ type: 'addStarterKit', product: starterKit, quantity: 1 });
    }
  }, []);

  const sort = (arr) => {
    const pos = arr.map(k => k.ProductID).indexOf(starterKit.ProductID)
    const filteredArr = arr.filter(k => k.ProductID !== starterKit.ProductID)
    return [arr[pos], ...filteredArr]
  };
  const sortedKits = sort(kits);

  const { title, buttonText } = data?.fields;

  return (
    <SaveModal
      loading={loading}
      onSave={() => save(state.starterKit)}
      onClose={() => close()}
      title={title}
      saveText={buttonText}
    >
      <section className="modal--starter-kit-wrapper">
        <StarterKits kits={sortedKits} label="Starter Kits" />
      </section>
    </SaveModal>
  );
}
