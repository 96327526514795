import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const MY = {
  serviceObjects: {
    autoComplete: false,
  },
  market: 'MY',
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
    ADDRESS2: {
      validationChecks: [match(fiftyCharactersMax, 'validation-max-50')],
    },
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      type: 'dropdown',
    },
  },
};
export default MY;
