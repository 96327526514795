import { useState } from 'react';
import { Typography, Stack, Divider, Link } from '@newulife/common'
import Price from 'components/common/Price';
import Volume from 'components/common/Volume';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { useProducts } from 'contexts/ProductContext';
import { useField } from 'hooks/useContentfulApi';

export default function CartOrderTotals({ type }) {

  if (type === 'subscription') return <Subscription />

  return <Default />
}

const Subscription = () => {
  const [data] = useField('banner1Ref');
  const { Totals } = useRecoilValue(formState);
  const { Total } = Totals || {};
  const { getTotalProductCV, getTotalProductQV } = useProducts();
  const cv = getTotalProductCV();
  const qv = getTotalProductQV();

  if (!data) return null;

  const { fields = {} } = data;

  const {
    item16: totalText = '',
    item17: cvText = '',
    item28: qvText = '',
  } = fields;

  return (
    <div className="total-review-summary">
      <div className="review-row border-top-bottom">
        <Typography
          as="p"
          variant="body3"
        >
          <span className="bold">{totalText}</span>
          <span className="font-18 bold total-cost" data-testid="total-Price">
            <Price value={Total} />
          </span>
        </Typography>
      </div>
      <Typography
        as="p"
        variant="body3"
        color="neutralCool.main"
      >
        <span>{cvText}</span>
        <span>
          <Volume type="amt" value={cv} />
        </span>{' '}
        <br />
      </Typography>
      <Typography
        as="p"
        variant="body3"
        color="neutralCool.main"
      >
        <span>{qvText}</span>
        <span>
          <Volume type="amt" value={qv} />
        </span>
        <br />
      </Typography>
    </div>
  );
};

const Default = () => {
  const [data] = useField('banner1Ref');
  const [promotionCodeRef] = useField('banner6Ref.fields');
  const { PromoCodeEvaluations, Totals } = useRecoilValue(formState);
  const { 
    CreditAmount,
    Discount,
    HandlingFee,
    HandlingTax,
    ProductTax,
    SubTotal,
    ShippingTax,
    ShippingTotal,
    Total,
    TotalTax,
  } = Totals || {};
  const promoCode = PromoCodeEvaluations?.Evaluations?.[0];
  const { getTotalProductCV, getTotalProductQV, getTotalProductPrice } = useProducts();
  const cv = getTotalProductCV();
  const qv = getTotalProductQV();
  // const subtotal = getTotalProductPrice();
  const [showDetails, setShowDetails] = useState(false);

  const {
    item13: subtotalText,
    item14: taxText,
    item15: shippingText,
    item16: totalText,
    item17: cvText,
    item28: qvText,
    item30: discountText,
    item32: showDetailsText,
    item33: hideDetailsText,
    item34: subtotalTaxText,
    item35: shippingTaxText,
    item36: handlingText,
    item37: handlingTaxText,
  } = data.fields;

  return (
    <Stack
      gap={1.5}
    >
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        <Typography
          variant="body1"
        >
          {subtotalText}
        </Typography>
        <Typography
          variant="body1"
          data-testid={subtotalText}
        >
          <Price value={SubTotal} />
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {shippingText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Price value={ShippingTotal} />
        </Typography>
        {HandlingFee !== 0 && (
          <>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {handlingText}
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              <Price value={HandlingFee} />
            </Typography>
          </>
        )}

        <Stack gap={.5}>
          {!!promoCode?.PromotionCode && (
            <Stack
              key={`code_${promoCode.PromotionCode}`}
              display="inline-grid"
              gridAutoFlow={{
                sm: 'column',
              }}
              justifyContent="flex-end"
              alignItems="baseline"
              gap={.5}
            >
              <Typography
                variant="body2"
                color="neutralCool.main"
              >
                {promotionCodeRef.text7}: {promoCode.PromotionCode}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack gap={.5}>
        {!!promoCode?.PromotionCode && (
            <Stack
              key={`promo-discount_${promoCode.PromotionCode}`}
              display="inline-grid"
              gridAutoFlow={{
                sm: 'column',
              }}
              justifyContent="flex-end"
              alignItems="baseline"
              gap={.5}
            >
              <Typography
                variant="body2"
                color="neutralCool.main"
              >
                {/* -<Price value={code.DiscountAmount} /> */}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          display="inline-grid"
          gridAutoFlow={{
            sm: 'column',
          }}
          justifyContent="flex-end"
          alignItems="baseline"
          gap={1}
        >
          <Typography
            variant="body2"
            color="neutralCool.main"
          >
            {taxText}
          </Typography>
          <Link
            type="button"
            underline="hover"
            sx={{
              fontSize: 14,
              cursor: 'pointer',
            }}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? hideDetailsText : showDetailsText}
          </Link>
        </Stack>
        <Typography
          variant="body2"
          color="neutralCool.main"
          data-testid={taxText}
        >
          <Price value={TotalTax} />
        </Typography>
        {showDetails && (
          <>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {subtotalTaxText}
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
              data-testid={subtotalTaxText}
            >
              <Price value={ProductTax} />
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {shippingTaxText}
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
              data-testid={shippingTaxText}
            >
              <Price value={ShippingTax} />
            </Typography>
            {HandlingTax !== 0 && (
              <>
                <Typography
                  variant="body2"
                  color="neutralCool.main"
                  data-testid={handlingTaxText}
                >
                  {handlingTaxText}
                </Typography>
                <Typography
                  variant="body2"
                  color="neutralCool.main"
                >
                  <Price value={HandlingTax} />
                </Typography>
              </>
            )}
          </>
        )}
      </Stack>
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        {Discount > 0 && (
          <>
            <Typography
              variant="body3"
              color="neutralCool.main"
            >
              {discountText}
            </Typography>
            <Typography
              data-testid="promo-discount-line"
              variant="body3"
              color="neutralCool.main"
              sx={{
                fontSize: 18,
              }}
            >
              -<Price value={Discount} />
            </Typography>
          </>
        )}
        <Typography
          variant="subhead1"
        >
          {totalText}
        </Typography>
        <Typography
          variant="subhead1"
        >
          <Price value={Total} />
        </Typography>
      </Stack>
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {cvText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Volume type="amt" value={cv} />
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {qvText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Volume type="amt" value={qv} />
        </Typography>
      </Stack>
    </Stack>
  );
};
