import order from 'api/order';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

export const usePaymentSession = () => {
  const { authRep, localeId, orderId, onlineOrderId, onlineSignupId } =
    useRecoilValue(formState);

  const createPaymentSession = async (payer) => {
    const isRepLoggedIn = !isEmpty(authRep);
    const { origin } = window.location;
    const payload = {
      onlineOrderId: onlineOrderId,
      onlineSignupId: onlineSignupId,
      redirectUrl: origin,
      authenticatedRepDid: isRepLoggedIn ? authRep.RepDID + '' : undefined,
      authenticatedRepId: isRepLoggedIn ? authRep.RepID + '' : undefined,
      payer: payer === 'rep' ? authRep.RepID : onlineSignupId,
      localeId,
    };

    try {
      const { checkoutUrl } = await order.createPaymentSession(payload);
      return { checkoutUrl };
    } catch (err) {
      return {};
    }
  };

  return { createPaymentSession };
};
