import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const SG = {
  market: 'SG',
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'ZIP'],
    ADDRESS2: {
      validationChecks: [match(fiftyCharactersMax, 'validation-max-50')],
    },
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
      mask: '999999',
    },
  },
};
export default SG;
