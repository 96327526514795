import parse from 'html-react-parser'
import Price from 'components/common/Price';
import { Stack, Box, Typography, Divider } from '@newulife/common'
import formatImageSrc from 'helpers/formatImageSrc'

/**
 * Creates a product card used for displaying information about the product.
 * https://corporate-ux.azurewebsites.net/ref/components-cards-card#product-card
 *
 * @param {string} imgSrc The url of the product image.
 * @param {string} imgAlt The alternate text of the product image.
 * @param {string} title The title of the product.
 * @param {string} contentHtml The html displayed inside the card (e.g. ingredients).
 * @param {number} price The price of the product.
 */
export default function StarterKitCard({
  imgSrc,
  imgAlt,
  title,
  contentHtml,
  price,
}) {
  return (
    <Box
      data-testid={`${title && title?.replace(/\W/g, '')}`}
      sx={{
        containerType: 'inline-size',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        alignItems: 'flex-start',
        flexDirection: 'column',
        border: '3px solid transparent',
        transition: 'all .2s ease',
        height: '100%',
        textAlign: 'center',
        '@container (min-width: 300px)': {
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'initial',
        },
      }}
    >
      <Stack
        display="grid"
        mb={2}
        justifyItems="center"
        sx={{
          '@container (min-width: 300px)': {
            gap: 3,
            gridTemplateColumns: 'auto 1fr',
            justifyItems: 'initial',
          },
        }}
      >
        <Box
          width="120px"
          height="120px"
          sx={{
            'img': {
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <img src={formatImageSrc(imgSrc)} alt={imgAlt} />
        </Box>
        <Stack
        >
          <Typography
            variant="body1"
            className="inherit-color"
          >
            {title}
          </Typography>
          <Box
            sx={{
              fontSize: 14,
              'strong': {
                display: 'inline-block',
                pb: 1,
                fontWeight: 400,
              },
              'ul': {
                listStyle: 'none',
                color: '#747679',
                fontSize: 14,
                borderTop: '1px solid #f2f2f2',
                borderBottom: '0 solid transparent',
                width: '100%',
                pt: 1,
                m: 0,
                '@container (min-width: 300px)': {
                  textAlign: 'left',
                },
              },
            }}
          >
            {typeof contentHtml === 'string' && parse(contentHtml)}
          </Box>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        gap={1.5}
      >
        <Divider
          light
        />
        <Typography
          variant="header2"
          textAlign="center"
          sx={{
            fontWeight: 500,
          }}
          data-testid={`${title?.replace(/\W/g, '')}-price`}
        >
          <Price value={price} />
        </Typography>
      </Stack>
    </Box>
  );
}
