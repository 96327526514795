import { moneyInfoState } from 'state';
import { useRecoilValueLoadable } from 'recoil';
import { isNumber } from 'lodash';

export default function Price({ value }) {
  const { contents: moneyInfo } = useRecoilValueLoadable(moneyInfoState)
  if (!isNumber(value)) return null;
  const price = value.toFixed(2);
  if (!moneyInfo) return null


  const priceWithComma = price
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (!moneyInfo?.currencySymbol || !moneyInfo?.currencyTypeCode) {
    return priceWithComma;
  } else if (moneyInfo?.currencySymbol.split('').length > 1) {
    return `${moneyInfo?.currencySymbol}${priceWithComma}`;
  }

  return `${moneyInfo?.currencySymbol}${priceWithComma} ${moneyInfo?.currencyTypeCode}`;
}
