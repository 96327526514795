import { useEffect, useState } from 'react';
import Header from 'components/steps/Header';
import Page from 'components/common/layout/Page';
import MainContent from 'components/common/layout/MainContent';
import Footer from 'components/steps/Footer';
import ShadowCard from 'components/common/cards/ShadowCard';
import {
  Typography,
  Stack,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@newulife/common';
import {
  NextContextProvider,
  useNextContextConsumer,
} from 'contexts/NextContext';
import Markdown from 'react-markdown';
import { useRecoilState } from 'recoil';
import { formState } from 'state';
import { ContentfulProvider, useField } from 'hooks/useContentfulApi';
import useQuery from 'hooks/useQuery';
import { usePaymentSession } from 'hooks/useCreatePaymentSession';

const PresentIcon = ({ active }) => {
  return (
    <Box
      sx={{
        path: {
          stroke: active ? undefined : '#747679',
        },
      }}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.2344 18.8281H4.76562V35.2344C4.76562 35.856 5.01256 36.4521 5.45209 36.8917C5.89163 37.3312 6.48777 37.5781 7.10938 37.5781H32.8906C33.5122 37.5781 34.1084 37.3312 34.5479 36.8917C34.9874 36.4521 35.2344 35.856 35.2344 35.2344V18.8281Z"
          stroke="url(#paint0_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.2344 11.7969H4.76562C4.14402 11.7969 3.54788 12.0438 3.10834 12.4833C2.66881 12.9229 2.42188 13.519 2.42188 14.1406V17.6562C2.42188 17.9671 2.54534 18.2651 2.76511 18.4849C2.98488 18.7047 3.28295 18.8281 3.59375 18.8281H36.4062C36.7171 18.8281 37.0151 18.7047 37.2349 18.4849C37.4547 18.2651 37.5781 17.9671 37.5781 17.6562V14.1406C37.5781 13.519 37.3312 12.9229 36.8917 12.4833C36.4521 12.0438 35.856 11.7969 35.2344 11.7969Z"
          stroke="url(#paint1_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8281 11.7969C13.65 11.7969 8.28125 7.6 8.28125 2.42188"
          stroke="url(#paint2_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.28125 2.42188C13.4594 2.42188 18.8281 6.61875 18.8281 11.7969"
          stroke="url(#paint3_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.1719 11.7969C26.35 11.7969 31.7188 7.6 31.7188 2.42188"
          stroke="url(#paint4_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.7188 2.42188C26.5406 2.42188 21.1719 6.61875 21.1719 11.7969"
          stroke="url(#paint5_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.4844 11.7969H23.5156V37.5781H16.4844V11.7969Z"
          stroke="url(#paint6_linear_7485_2897)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7485_2897"
            x1="4.76562"
            y1="37.5781"
            x2="21.5038"
            y2="10.3786"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7485_2897"
            x1="2.42188"
            y1="18.8281"
            x2="5.1262"
            y2="5.30649"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7485_2897"
            x1="8.28125"
            y1="11.7969"
            x2="17.5916"
            y2="1.32274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7485_2897"
            x1="8.28125"
            y1="11.7969"
            x2="17.5916"
            y2="1.32274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_7485_2897"
            x1="21.1719"
            y1="11.7969"
            x2="30.4822"
            y2="1.32274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_7485_2897"
            x1="21.1719"
            y1="11.7969"
            x2="30.4822"
            y2="1.32274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_7485_2897"
            x1="16.4844"
            y1="37.5781"
            x2="29.5733"
            y2="34.0084"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

const CashPaymentIcon = ({ active }) => {
  return (
    <Box
      sx={{
        path: {
          stroke: active ? undefined : '#747679',
        },
      }}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ stroke: active ? undefined : '#747679' }}
      >
        <path
          d="M7.21875 26.4375L10.8594 24.875C11.2226 24.6247 11.5287 24.3004 11.7576 23.9234C11.9865 23.5464 12.133 23.1252 12.1875 22.6875L12.7656 19.25L17.6875 15.7343C17.9111 15.5983 18.097 15.4082 18.2281 15.1816C18.3591 14.955 18.4311 14.6992 18.4375 14.4375V13.5312C18.4566 13.2669 18.4081 13.002 18.2966 12.7615C18.1852 12.5211 18.0143 12.3129 17.8003 12.1566C17.5862 12.0004 17.3359 11.9011 17.0729 11.8683C16.8099 11.8354 16.5428 11.87 16.2969 11.9687L9.70312 14.375C8.05586 14.9574 6.52643 15.8306 5.1875 16.9531L2.42188 19.25"
          stroke="url(#paint0_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.42188 35.2344L6.01562 32.6094C6.78216 32.0345 7.7137 31.7221 8.67188 31.7188H20.7031C21.6482 31.7203 22.5717 31.4362 23.3525 30.9036C24.1332 30.371 24.7347 29.6149 25.0781 28.7344L27.2656 23.125"
          stroke="url(#paint1_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.8594 16.2345C23.1969 16.6812 23.6375 17.0396 24.1436 17.279C24.6497 17.5184 25.2062 17.6318 25.7656 17.6095C27.5469 17.6095 28.9844 16.5313 28.9844 15.1876C28.9844 13.8438 27.5469 12.7813 25.7656 12.7813C23.9844 12.7813 22.5312 11.6876 22.5312 10.3595C22.5312 9.03133 23.9844 7.93758 25.7656 7.93758C26.3251 7.91522 26.8816 8.0286 27.3877 8.26804C27.8938 8.50748 28.3344 8.86587 28.6719 9.31258"
          stroke="url(#paint2_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.7656 17.6094V19.2188"
          stroke="url(#paint3_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.7656 6.32812V7.9375"
          stroke="url(#paint4_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.3281 4.76562H35.2344C35.856 4.76562 36.4521 5.01256 36.8917 5.45209C37.3312 5.89163 37.5781 6.48777 37.5781 7.10938V20.7812C37.5781 21.4029 37.3312 21.999 36.8917 22.4385C36.4521 22.8781 35.856 23.125 35.2344 23.125H17.6562"
          stroke="url(#paint5_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.45312 9.45312V7.10938C9.45312 6.48777 9.70006 5.89163 10.1396 5.45209C10.5791 5.01256 11.1753 4.76563 11.7969 4.76562H20.7812"
          stroke="url(#paint6_linear_7485_1813)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7485_1813"
            x1="2.42188"
            y1="26.4375"
            x2="16.9388"
            y2="10.4884"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7485_1813"
            x1="2.42188"
            y1="35.2344"
            x2="11.9604"
            y2="15.6649"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7485_1813"
            x1="22.5312"
            y1="17.6122"
            x2="31.4651"
            y2="11.655"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7485_1813"
            x1="25.7656"
            y1="19.2188"
            x2="27.2085"
            y2="18.3222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_7485_1813"
            x1="25.7656"
            y1="7.9375"
            x2="27.2085"
            y2="7.04093"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_7485_1813"
            x1="17.6562"
            y1="23.125"
            x2="35.9546"
            y2="3.26939"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_7485_1813"
            x1="9.45312"
            y1="9.45312"
            x2="12.7653"
            y2="1.44868"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0083BE" />
            <stop offset="1" stop-color="#7AB800" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

function PayPage() {
  const { createPaymentSession } = usePaymentSession();
  const [data] = useField();
  const [form, setForm] = useRecoilState(formState);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const isDisabled = !selectedPayer || (selectedPayer && !hasConfirmed);
  const query = useQuery();
  const { next, setNext, tip, setTip } = useNextContextConsumer();

  const validate = async () => {
    try {
      return await createPaymentSession(selectedPayer);
    } catch (err) {
      // LET THE ERROR BE HANDLED BY REQUEST ERROR HANDLER
      return {};
    }
  };

  useEffect(() => {
    setTip({ ...tip, message: 'Please select a payer.' });
    setNext({ ...next, enabled: !isDisabled, validate });
  }, [isDisabled, selectedPayer]);

  const { banner1Ref: pageData, banner2Ref: failedPayment } = data;

  const handleSetPayer = (payer) => {
    setSelectedPayer(payer);
    setHasConfirmed(false);
  };

  return (
    <Page className="review-screen">
      <Header />
      <MainContent>
        <section className="intro-text center">
          <Typography variant="header1" color="neutralCool.main">
            {pageData?.fields?.title}
          </Typography>
        </section>
        {query.get('failedPayment') && (
          <Alert
            severity="error"
            variant="standard"
            sx={{
              mx: 'auto',
              mb: '40px',
            }}
          >
            <Typography as="p" variant="body2" color="inherit">
              {failedPayment?.fields?.textString}
            </Typography>
          </Alert>
        )}
        <ShadowCard
          sx={{
            pb: 0,
          }}
        >
          <Stack gap={2} p={2}>
            <>
              <Paper
                data-testid="rep"
                className="form-max"
                elevationVariant="section"
                sx={{
                  p: 2,
                  borderRadius: '17px',
                  cursor: 'pointer',
                }}
                active={selectedPayer === 'rep'}
                onClick={() => handleSetPayer('rep')}
              >
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* TODO: check on businessMarket */}
                  <Stack direction="row" gap={2} alignItems="center">
                    <PresentIcon active={selectedPayer === 'rep'} />
                    <Typography>
                      {pageData?.fields?.item1} (
                      {form.businessMarket?.iso2 === 'CN'
                        ? form.repDID
                        : form.repName}
                      )
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
              {selectedPayer === 'rep' && (
                <Stack className="form-max">
                  <FormControlLabel
                    sx={{
                      alignItems: 'flex-start',
                    }}
                    control={
                      <Checkbox
                        id="confirm"
                        checked={hasConfirmed}
                        onChange={() => setHasConfirmed(!hasConfirmed)}
                      />
                    }
                    label={
                      <Stack gap={1} pt={0.3}>
                        <Box
                          data-testid="rep-label"
                          as="label"
                          sx={{
                            '> *': {
                              color: 'neutralCool.main',
                              fontWeight: '700',
                              fontSize: 18,
                              lineHeight: '1.125em',
                              cursor: 'pointer',
                            },
                          }}
                          htmlFor="confirm"
                        >
                          <Markdown>{pageData?.fields?.item4}</Markdown>
                        </Box>
                      </Stack>
                    }
                  />
                </Stack>
              )}
            </>
            <Paper
              data-testid="accountHolder"
              className="form-max"
              elevationVariant="section"
              sx={{
                p: 2,
                borderRadius: '17px',
                cursor: 'pointer',
              }}
              active={selectedPayer === 'accountHolder'}
              onClick={() => {
                handleSetPayer('accountHolder');
              }}
            >
              <Stack direction="row" gap={2} justifyContent="space-between">
                <Stack direction="row" gap={2} alignItems="center">
                  <CashPaymentIcon active={selectedPayer === 'accountHolder'} />
                  <Typography>{pageData?.fields?.item2}</Typography>
                </Stack>
              </Stack>
            </Paper>
            {selectedPayer === 'accountHolder' && (
              <Stack className="form-max">
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      id="confirm-account"
                      checked={hasConfirmed}
                      onChange={() => setHasConfirmed(!hasConfirmed)}
                    />
                  }
                  label={
                    <Stack gap={1} pt={0.3}>
                      <Box
                        data-testid="accountHolder-label"
                        as="label"
                        sx={{
                          '> *': {
                            color: 'neutralCool.main',
                            fontWeight: '700',
                            fontSize: 18,
                            lineHeight: '1.125em',
                            cursor: 'pointer',
                          },
                        }}
                        htmlFor="confirm-account"
                      >
                        <Markdown>{pageData?.fields?.item3}</Markdown>
                      </Box>
                    </Stack>
                  }
                />
              </Stack>
            )}
          </Stack>
        </ShadowCard>
      </MainContent>
      <Footer />
    </Page>
  );
}

export default function Pay() {
  return (
    <NextContextProvider>
      <ContentfulProvider page="pay">
        <PayPage />
      </ContentfulProvider>
    </NextContextProvider>
  );
}
