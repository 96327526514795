import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const HK = {
  serviceObjects: {
    autoComplete: false,
  },
  market: 'HK',
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE'],
        },
      },
    },
  },
  inputs: {
    ADDRESS2: {
      validationChecks: [match(fiftyCharactersMax, 'validation-max-50')],
    },
    STATE: {
      labelRef: 'DISTRICT',
      type: 'dropdown',
    },
  },
};
export default HK;
