import { isNull } from 'lodash';
import CartOrderTotals from 'components/common/cart/CartOrderTotals';
import ShadowCard from 'components/common/cards/ShadowCard';
import { useProducts } from 'contexts/ProductContext';
import ProductOrderStarterKitSection from './ProductOrderStarterKitSection';
import ProductOrderProductSection from './ProductOrderProductSection';
import ProductOrderPromoSection from './ProductOrderPromoSection';
import { useField } from 'hooks/useContentfulApi';

export default function ProductOrder({
  promos = [],
  onUpdateCart,
}) {
  const [title] = useField('banner1Ref.fields.item6');
  const {
    state: { starterKit, products },
  } = useProducts();

  // If there is no starter kit or products in cart, don't render.
  if (isNull(starterKit) && products.length === 0) {
    return null;
  }

  return (
    <ShadowCard title={title}>
      <div className="order-summary">
        <ProductOrderStarterKitSection
          starterKit={starterKit}
          onUpdateCart={onUpdateCart}
        />
        <ProductOrderProductSection
          products={products}
          onUpdateCart={onUpdateCart}
        />
        <ProductOrderPromoSection promos={promos} />
        <CartOrderTotals />
      </div>
    </ShadowCard>
  );
}
