import { get } from 'api/request';

function deprecated_getDataByProperty(url, value, displayText) {
  return get(url).then((data) => {
    if (data?.error) {
      return data;
    }
    return data.map((tableRow) => {
      if (!tableRow[value]) console.debug('mapping failed for ' + url);
      return {
        text: tableRow[displayText] ?? tableRow[value],
        value: tableRow[value]
      };
    });
  });
}

function getData(url) {
  return get(url).then((data) => {
    if (data?.error) return data
    return data?.map?.(o => ({
      text: o?.Title,
      value: o?.Value,
    }));
  });
}

function isThreeDigits(string = '') {
  const isValid = string.replace('_', '')?.length === 3;
  return isValid;
}

// country is from Form > countryInfo > name
export const functionsByCountry = {
  //@todo switch to china/hkcb
  CN: {
    STATE: () => deprecated_getDataByProperty('/api/getHKCBProvinces', 'provinceName'),
    CITY: (args) => {
      const city = args.default;
      if (!city) return Promise.resolve([]);
      return deprecated_getDataByProperty(`/api/getHKCBCityNames/:${city}`, 'cityName');
    },
    COUNTY: (args) => {
      const county = args.default;
      if (!county) return Promise.resolve([]);
      return deprecated_getDataByProperty(
        `/api/getHKCBDistricts/:${county}`,
        'districtName',
      );
    },
  },
  TW: {
    STATE: (args) => {
      const postalCode = args.default;
      if (!isThreeDigits(postalCode)) return Promise.resolve([]);
      return deprecated_getDataByProperty(`/api/getTaiwanArea/:${postalCode}`, 'cityCountyName');
    },
    CITY: (args) => {
      const postalCode = args.default;
      if (!isThreeDigits(postalCode)) return Promise.resolve([]);
      return deprecated_getDataByProperty(
        `/api/getTaiwanCity/:${postalCode}`,
        'areaName',
      );
    },
  },
  HK: {
    STATE: (args) => deprecated_getDataByProperty(`/api/getHongKongStates?locale=${args.locale}`, 'stateName', 'translatedStateName'),
  },
  MY: {
    STATE: () => getData('/api/enrollment/states/malaysia'),
  },
  TH: {
    STATE: () => getData('/api/enrollment/states/thailand'),
  },
  KR: {
    NAME_BANK: () => {
      return deprecated_getDataByProperty('/api/getKoreanBankNames', 'BankNameKorean');
    },
    STATE: () => getData('/api/enrollment/states/KOREA (THE REPUBLIC OF)'),
  },
};

export default async function getDropDownData(country, id, args) {
  try {
    if (!functionsByCountry[country]) {
      throw new Error(`NO api calls for dropdown menu in country-${country}`);
    }
    if (!functionsByCountry[country][id]) {
      throw new Error(`NO api call for dropdown menu "${id}" in country-${country}`);
    }
    const results = await functionsByCountry[country][id](args);
    return results;
  } catch (message) {
    console.error(`failed to GET - ${id} - ${message}`);
  }
};
