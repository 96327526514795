import { chinaGovId } from 'components/common/form/formValidationRules';
import { chinaIdValidation } from 'api/validation';
import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const CN = {
  serviceObjects: {
    autoComplete: false,
  },
  market: 'CN',
  enrollment: {
    form: {
      sections: {
        account: {
          fields: ['NAME_FIRST', 'NAME_LAST', 'COMPANY', 'EMAIL', 'PASSWORD'],
        },
        shipping: {
          fields: ['STATE', 'CITY', 'COUNTY', 'ADDRESS1', 'ADDRESS2'],
        },
        identity: {
          fields: ['GOVERNMENT_NAME', 'GOVERNMENT_ID'],
          order: 5,
          onBlur: chinaIdValidation,
        },
      },
    },
  },
  inputs: {
    ADDRESS2: {
      validationChecks: [match(fiftyCharactersMax, 'validation-max-50')],
    },
    STATE: {
      type: 'dropdown',
      labelRef: 'PROVINCE',
    },
    CITY: {
      dataLink: 'STATE',
      type: 'dropdown',
      dataLink: 'STATE',
    },
    COUNTY: {
      dataLink: 'CITY',
      labelRef: 'DISTRICT',
      hide: false,
      type: 'dropdown',
    },
    IDENTITY: {
      onBlur: chinaGovId,
    },
  },
};
export default CN;
