import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import AddProductOrSubscriptions from 'components/steps/starter-kit/select-product/AddProductOrSubscriptions';
import { NextContextProvider } from 'contexts/NextContext';
import { ContentfulProvider, useField } from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';

export default function SelectProduct() {

  return (
    <NextContextProvider>
      <ContentfulProvider page="select-product">
        <Page className="add-products">
          <Header />
          <MainContent>
            <AddProductOrSubscriptions />
          </MainContent>
          <Footer />
        </Page>
      </ContentfulProvider>
    </NextContextProvider>
  );
}
