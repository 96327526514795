import { isNull } from 'lodash';
import ReviewYourOrder from 'components/steps/review/ReviewYourOrder';
import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import { NextContextProvider } from 'contexts/NextContext';
import { ContentfulProvider } from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function Review() {
  const { orderId, userId, localeId } = useRecoilValue(formState);
  
  if (isNull(orderId) || isNull(userId) || isNull(localeId)) {
    return null;
  }

  return (
    <NextContextProvider>
      <ContentfulProvider page="review">
        <Page className="review-screen">
          <Header />
          <MainContent>
            <ReviewYourOrder />
          </MainContent>
          <Footer />
        </Page>
      </ContentfulProvider>
    </NextContextProvider>
  );
}
