import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import YourStarterKit from 'components/steps/starter-kit/select-kit/YourStarterKit';
import { NextContextProvider } from 'contexts/NextContext';
import { ContentfulProvider } from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { useNavigate } from 'react-router-dom';


export default function SelectKit() {
  const navigate = useNavigate();

  const formData = useRecoilValue(formState);

  if (!formData.rep.RepDID) {
    navigate('/');
  }

  return (
    <NextContextProvider>
      <ContentfulProvider page="select-kit">
        <Page className="starter-kit-screen">
          <Header />
          <MainContent>
            <YourStarterKit />
          </MainContent>
          <Footer />
        </Page>
      </ContentfulProvider>
    </NextContextProvider>
  );
}
