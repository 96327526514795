import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import { formState, repState } from 'state';
import { getRepName, parseFullName } from 'helpers/rep';
import { LoaderRing } from '../common/loaders/SpinnerLoader';
import SponsorCard from '../common/cards/SponsorCard';
import { Button } from '@newulife/common';
import { useEnvironment } from 'contexts/EnvironmentContext';

export default function Sponsor({ data, isAuthed }) {
  const [newRepDID, setNewRepDID] = useState(''); // repDID state after query
  const [repDIDToQuery, setRepDIDToQuery] = useState(''); // input state for repDIDQuery
  const sponsor = useRecoilValueLoadable(repState(repDIDToQuery)); // get Loadable from recoil to handle UI states
  const [form, setForm] = useRecoilState(formState);
  const { fields } = data;
  const { contents, state } = sponsor;
  const name = parseFullName(contents?.FirstName, contents?.LastName);
  const isDevEnvironment = useEnvironment();
  const handleSubmit = (e) => {
    e.preventDefault();
    setRepDIDToQuery(newRepDID);
  };

  useEffect(() => {
    if (contents?.RepDID) {
      setForm({
        ...form,
        rep: contents,
        authRep: isAuthed ? contents : {},
        repDID: contents.RepDID,
        repName: getRepName(contents),
      });
    }
  }, [contents, setForm]);

  return contents?.RepDID ? (
    <RepSponsorCard
      PublicImageUrl={contents.PublicImageUrl}
      name={name}
      fields={fields}
      RepDID={contents.RepDID}
      newRepDID={newRepDID}
    />
  ) : (
    <RepSearchCard
      handleSubmit={handleSubmit}
      error={contents?.error}
      newRepDID={newRepDID}
      setNewRepDID={setNewRepDID}
      fields={fields}
      loading={state === 'loading'}
    />
  );
}

const RepSponsorCard = ({
  PublicImageUrl,
  name,
  fields,
  RepDID,
  newRepDID,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  return (
    <div className="form-max">
      <section className="card--container">
        <SponsorCard
          imgUrl={PublicImageUrl}
          name={name}
          idText={fields.item15}
          repDID={RepDID || newRepDID}
        />
      </section>
      <div className="flex-20 center column">
        <Button
          onClick={() => navigate(`/steps/starter-kit/select-kit${search}`)}
        >
          {fields.item10}
        </Button>
        <p className="member-login" data-testid="memberLogin">
          {fields.item11} <a href={fields.item13}>{fields.item12}</a>.
        </p>
      </div>
    </div>
  );
};

const RepSearchCard = ({
  handleSubmit,
  error,
  setError,
  newRepDID,
  setNewRepDID,
  fields,
  loading,
}) => (
  <div className="form-max">
    <section className="card--container">
      <div className="card sponsor-card">
        <div className="inner-content">
          <form className={loading ? 'fadeOut' : ''} onSubmit={handleSubmit}>
            <div className={!error ? 'input-wrapper' : 'input-wrapper error'}>
              <input
                id="sponsorID"
                data-testid="sponsorID"
                name="sponsorID"
                type="text"
                required
                value={newRepDID}
                onChange={(e) => setNewRepDID(e.target.value)}
              />
              <label htmlFor="sponsorID" aria-label="Sponsor Identification">
                {fields.item2}
              </label>
              <p className="helper-text" data-testid="message">
                {!error ? fields.item3 : fields.item4}
              </p>
            </div>
          </form>
          <LoaderRing active={loading} />
        </div>
      </div>
    </section>
    <div className="flex-20 center column">
      <Button disabled={!newRepDID} onClick={handleSubmit}>
        {fields.item9}
      </Button>
      <p className="member-login" data-testid="memberLogin">
        {fields.item11} <a href={fields.item13}>{fields.item12}</a>.
      </p>
    </div>
  </div>
);
