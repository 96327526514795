import { useRef, useState } from 'react';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { Select, MenuItem } from '@newulife/common';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { find } from 'lodash'

/**
 * Creates a form select dropdown.
 * https://corporate-ux.azurewebsites.net/ref/components-form-elements-form#properties
 *
 * @param {boolean} active Whether the select dropdown is currently clicked into.
 * @param {function} setActive Setter function for active state.
 * @param {string} selectId The id of the select button element.
 * @param {string} labelText The label text of the select element.
 * @param {Object[]} options The options shown in the dropdown.
 * @param {string} options[].text - The text of the option.
 * @param {string} value The current option selected.
 * @param {function} onChange The handler function that changes the current selected value.
 */

const getValue = (options, value) => {
  return find(options, { value: value?.toUpperCase?.() }) ? value?.toUpperCase?.() : value
}

export default function FormSelectDropdown({
  active,
  setActive,
  selectId,
  labelText,
  options = [],
  value: defaultValue = '',
  onChange,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setActive(false));
  const [value, setValue] = useState(getValue(options, defaultValue));

  const handleChange = (e) => {
    setValue(getValue(options, e.target.value));
    onChange(getValue(options, e.target.value));
  };

  return (
    <FormControl>
      <InputLabel
        id={`${selectId}-label`}
        color="secondary"
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        {labelText}
      </InputLabel>
      <Select 
        id={selectId}
        labelId={`${selectId}-label`} 
        value={getValue(options, value)} 
        onChange={handleChange}
      >
        {options.map(option => {
          const itemValue = option.value ?? option.text
          return (
            <MenuItem 
              key={itemValue} 
              value={itemValue}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
}

export function DropDownWrapper(props) {
  const { onChange: onChangeFromProps, id, label, options, value } = props;
  const [active, set_active] = useState(false);

  function onChange(event) {
    set_active(!active);
    onChangeFromProps(event);
  }

  const newProps = {
    active,
    setActive: set_active,
    selectId: id,
    labelText: label,
    options: options.map((option) => {
      return { text: option };
    }),
    value,
    onChange,
  };
  return <FormSelectDropdown {...newProps} />;
}
