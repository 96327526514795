import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';

const Kanji_Hiragana_Katakana_Four_Roman_Regex =
  /^[\p{Script=Han}\p{Script=Hiragana}\p{Script=Katakana}0-9\- ]+(?=(?:[^A-Za-z]*[A-Za-z]){0,4}[^A-Za-z]*$)/u;
const Kanji_Katakana_Regex = /^[\p{Script=Han}\p{Script=Katakana}]+$/u;
const Kanji_Regex = /^[\p{Script=Han}]+$/u;
const Half_Width_Katakana_Regex = /^[\uFF65-\uFF9F]+$/u;

const JP = {
  forceSingleAddress: true,
  market: 'JP',
  enrollment: {
    form: {
      sections: {
        account: {
          fields: [
            'NAME_LAST',
            'NAME_FIRST',
            'NICKNAME',
            'ROMAN_CHARACTER_NAME',
            'COMPANY',
            'EMAIL',
            'PASSWORD',
          ],
        },
        shipping: {
          fields: ['ZIP', 'STATE', 'CITY', 'COUNTY', 'ADDRESS1', 'ADDRESS2'],
        },
        misc: {
          fields: ['JAPAN_SERIAL_NUMBER', 'GAIYOSHOMEN'],
          order: 2,
          localeEntry: 7,
        },
        terms: {
          fields: ['AGE', 'TERMS'],
          localeEntry: 6,
        },
        bank: {
          fields: ['BANK_ACCOUNT', 'jp-bank-info'],
          order: 4,
          localeEntry: 3,
        },
        tax: {
          fields: ['MY_NUMBER'],
          order: 3,
          localeEntry: 4,
        },
      },
    },
  },
  inputs: {
    COMPANY: {
      required: false,
      validationChecks: [
        match(Kanji_Katakana_Regex, 'validation-kanji-katakana-only'),
      ],
    },
    BANK_ACCOUNT_NAME: {
      validationChecks: [
        match(Half_Width_Katakana_Regex, 'validation-half-width-katakana-only'),
      ],
    },
    NAME_ON_BANK: {
      required: false,
    },
    BANK_ACCOUNT: {
      required: false,
      mask: '9999999',
    },
    NAME_BANK: {
      required: false,
    },
    NAME_BRANCH: {
      required: false,
    },
    ID_BANK: {
      required: false,
    },
    ID_BRANCH: {
      required: false,
    },
    NAME_FIRST: {
      validationChecks: [match(Kanji_Regex, 'validation-kanji-only')],
    },
    NAME_LAST: {
      validationChecks: [match(Kanji_Regex, 'validation-kanji-only')],
    },
    AGE: {
      labelRef: 'AGE_20',
    },
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      labelRef: 'PREFECTURE',
      validationChecks: [
        match(
          Kanji_Hiragana_Katakana_Four_Roman_Regex,
          'validation-japanese-only',
        ),
      ],
    },
    CITY: {
      labelRef: 'MUNICIPALITY',
      validationChecks: [
        match(
          Kanji_Hiragana_Katakana_Four_Roman_Regex,
          'validation-japanese-only',
        ),
      ],
    },
    COUNTY: {
      labelRef: 'DISTRICT',
      validationChecks: [
        match(
          Kanji_Hiragana_Katakana_Four_Roman_Regex,
          'validation-japanese-only',
        ),
      ],
    },
    ADDRESS1: {
      labelRef: 'Street Name and Number',
      validationChecks: [
        match(
          Kanji_Hiragana_Katakana_Four_Roman_Regex,
          'validation-japanese-only',
        ),
      ],
    },
    ADDRESS2: {
      labelRef: 'Apartment No. + Room No.',
      validationChecks: [
        match(
          Kanji_Hiragana_Katakana_Four_Roman_Regex,
          'validation-japanese-only',
        ),
        match(fiftyCharactersMax, 'validation-max-50'),
      ],
    },
    JapanSerialNumber: {
      labelRef: 'Japan Serial Number',
      mask: 'A*********',
    },
  },
};
export default JP;
