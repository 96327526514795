import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { init as initApm } from '@elastic/apm-rum'
import Confirmation from 'pages/steps/confirmation'
import Pay from 'pages/steps/pay'
import Review from 'pages/steps/review'
import CreateAccount from 'pages/steps/create-account'
import CommissionsSetup from 'pages/steps/commissions-setup'
import SelectProduct from 'pages/steps/starter-kit/select-product'
import SelectKit from 'pages/steps/starter-kit/select-kit'
import NiceCallback from 'pages/callback/nice'
import Home from 'pages/index'
import SubscribePage from 'pages/subscribe'
import ErrorBoundary from 'components/common/errors/ErrorBoundary'
import config from 'config'
import { useEnvironment } from 'contexts/EnvironmentContext'
import DevTools from 'components/tools/DevTools'

const apm = initApm({
  active: config.ELASTIC_APM_ENABLED,
  environment: config.APP_ENV,
  logLevel: config.ELASTIC_APM_LEVEL || 'debug',
  serverUrl: config.ELASTIC_URI,
  serviceName: require('../package.json').name,
  serviceVersion: process.env.REACT_APP_VERSION || '0.0.0'
})

export default function App() {
  const isDevEnvironment = useEnvironment()

  return (
    <ErrorBoundary>
      <Router>
        <ApmRoutes>
          <Route path="/steps/confirmation" element={<Confirmation />} />
          <Route path="/steps/pay" element={<Pay />} />
          <Route path="/steps/review" element={<Review />} />
          <Route path="/steps/create-account" element={<CreateAccount />} />
          <Route path="/steps/commissions-setup" element={<CommissionsSetup />} />
          <Route path="/steps/starter-kit/select-product" element={<SelectProduct />} />
          <Route path="/steps/starter-kit/select-kit" element={<SelectKit />} />
          <Route path="/nice-response" element={<NiceCallback />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </ApmRoutes>
        {isDevEnvironment && <DevTools />}
      </Router>
    </ErrorBoundary>
  )
}
