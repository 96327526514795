import {
  match,
  validKrBirthdayInId,
  krEmail,
  uniqueEmail,
} from 'components/common/form/formValidationRules';
import {
  koreanAddressRegex,
  hangulOnlyRegex,
  tenToThirteenDigitsAndHyphenRegex,
  noHyphenOrSpaceRegex,
  fiftyCharactersMax,
} from 'helpers/regex';
import envConfig from 'config';

const KR = {
  serviceObjects: {
    autoComplete: false,
  },
  enrollment: {
    form: {
      sections: {
        personal: {
          fields: ['REAL_NAME', 'RESIDENT_REGISTRATION_NUMBER'],
          order: 1,
        },
        shipping: {
          fields: ['STATE', 'CITY', 'ADDRESS1', 'ZIP'],
          localeEntry: 2,
          order: 3,
        },
        bank: {
          order: -1, // this has a default of 3 and is overriding the shipping section
        },
        banking: {
          fields: [
            'BANK_ACCOUNT_TYPE',
            'NAME_BANK',
            'NAME_ON_BANK',
            'BANK_ACCOUNT',
          ],
        },
        terms: {
          fields: ['AGE_20', 'TERMS', 'KR_PRIVACY_POLICY'],
        },
        ...(envConfig.FEATURE_FLAG_PHONE_VALIDATION && {
          kr_phone_validation: {
            localeEntry: 7,
            order: 2,
          },
        }),

        // ORIGINAL FOR REFERENCE
        // account: {
        //   fields: ['NAME_FIRST', 'NAME_LAST', 'COMPANY', 'EMAIL', 'PASSWORD'],
        //   order: 0,
        // },
        // shipping: {
        //   fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE', 'ZIP'],
        //   order: 1,
        // },
        // personal: {
        //   fields: undefined,
        //   order: 2,
        // },
        // bank: {
        //   fields: undefined,
        //   order: 3,
        // },
        // tax: {
        //   fields: undefined,
        //   order: 4,
        // },
        // identity: {
        //   fields: undefined,
        //   order: 5,
        // },
        // terms: {
        //   fields: ['AGE', 'TERMS'],
        //   order: 6,
        // },
      },
    },
  },
  market: 'KR',
  inputs: {
    BANK_ACCOUNT: {
      labelRef: 'BANK_ACCOUNT',
      validationChecks: [
        match(noHyphenOrSpaceRegex, 'validation-no-hyphen-or-space'),
      ],
    },
    DOB: {
      mask: '9999/99/99',
    },
    NAME_BANK: {
      type: 'dropdown',
    },
    PHONE: {
      labelRef: 'PHONE',
    },
    REAL_NAME: {
      labelRef: 'REAL_NAME',
      ...(!envConfig.FEATURE_FLAG_KR_ALLOW_FOREIGN_RRN && {
        validationChecks: [match(hangulOnlyRegex, 'validation-hangul-only')],
      }),
    },
    RESIDENT_REGISTRATION_NUMBER: {
      labelRef: 'RESIDENT_REGISTRATION_NUMBER',
      mask: '999999-9999999',
      validationChecks: [
        match(tenToThirteenDigitsAndHyphenRegex, 'kr-validation-length-format'),
        validKrBirthdayInId(),
      ],
      // onBlur: krGovIdValidation,
    },
    ZIP: {
      mask: '99999',
      required: false,
      labelRef: 'POSTAL_CODE',
    },
    ADDRESS1: {
      labelRef: 'Street Name and Number',
      validationChecks: [match(koreanAddressRegex, 'validation-hangul-only')],
    },
    STATE: {
      type: 'dropdown',
      labelRef: 'PROVINCE',
    },
    ADDRESS2: {
      labelRef: 'UNIT_FLOOR',
      validationChecks: [
        match(koreanAddressRegex, 'validation-hangul-only'),
        match(fiftyCharactersMax, 'validation-max-50'),
      ],
    },
    AGE_20: {
      type: 'switch',
    },
    KR_PRIVACY_POLICY: {
      type: 'switch',
    },
    EMAIL: {
      type: 'email',
      validationChecks: [krEmail()],
      onBlur: uniqueEmail,
    },
  },
};
export default KR;
