import { toast } from 'react-toastify';
import { post, put, get } from './request';
import { createEnrollData } from 'helpers/parse';

const order = {
  create: (data) => {
    return post('/api/createEnrollOrder', data).then(
      (orderInfo) => orderInfo?.[0]?.id,
    );
  },
  createItems: (items, orderId, country) => {
    const products = items.map((item) => ({ ...item, orderId, country }));
    return post('/api/orderItems', { products }, {});
  },
  updateCart: (data) => {
    return put('/api/enrollment/order', data);
  },
  updateTotals: (orderRow) => {
    return post('/api/orderUpdateTotalsEnrollment', {
      orderRow,
    });
  },
  updateOrderAndRepId: (orderInfo) => {
    return post('/api/updateOrderAndRepId', orderInfo);
  },
  getDetails: (id) => {
    return get(`/api/getOrderDetails/:${id}`);
  },
  getGtmInfo: ({ orderId }) => {
    return get(`/api/getGtmInfoForOrders/:${orderId}`);
  },
  createAll: async (enrollData) => {
    const data = createEnrollData(enrollData);
    const { errors, userId, orderId, onlineOrderId, onlineSignupId } =
      await post('/api/createUserComplete', data);
    if (!userId || !orderId || !onlineOrderId)
      throw Object.assign(new Error('Unable To Create Order'), { errors });
    if (errors.length > 0)
      throw Object.assign(new Error('Error saving account'), { errors });
    return { userId, orderId, onlineOrderId, onlineSignupId };
  },
  createPaymentSession: (data) => {
    return post('/api/enrollment/payment-session', data);
  },
};

export default order;
