import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import ProductSubscriptions from './ProductSubscriptions';
import { Typography } from '@newulife/common'
import { useField } from 'hooks/useContentfulApi';

export default function AddProductOrSubscriptions() {
  const [data] = useField('banner1Ref');
  const { enableSkip, replaceSkip } = useNextContextConsumer();
  const {
    state: { products },
  } = useProducts();
  const {
    state: { subscriptions },
  } = useSubscriptions();
  const { title, item1, item5 } = data.fields;

  useEffect(() => {
    if (isEmpty(products) && isEmpty(subscriptions)) {
      enableSkip();
    } else {
      replaceSkip();
    }
  }, [isEmpty(products), isEmpty(subscriptions)]);

  return (
    <>
      <section className="intro-text center">
        <Typography
          variant="header1"
          textAlign="center"
          mb={2}
        >
          {title}
        </Typography>
        <Typography
          variant="subhead2"
          textAlign="center"
          mb={1.5}
          sx={{
            maxWidth: 440,
          }}
        >
          {item1}
        </Typography>
        <Typography
          variant="subhead1"
        >
          {item5}
        </Typography>
      </section>
      <ProductSubscriptions cardText={data} />
    </>
  );
}
