import address from './address';
import contentful from './contentful';
import dropdowns from './dropdowns';
import order from './order';
import product from './product';
import shipping from './shipping';
import subscription from './subscription';
import user from './user';
import getLanguages from './getLanguages';
import { getMarkets, getCountries } from './market';

const index = {
  user,
  product,
  subscription,
  order,
  shipping,
  dropdowns,
  address,
  contentful,
  getMarkets,
  getCountries,
  getLanguages,
};

export default index;
