import { useEffect, useState, useRef } from 'react';
import { isNull } from 'lodash';
import order from 'api/order';
import shipping from 'api/shipping';
import subscription from 'api/subscription';
import { useLoader } from 'contexts/LoaderContext';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import ProductOrder from './ProductOrder';
import SelectShippingMethod from './SelectShippingMethod';
import SubscriptionOrder from './SubscriptionOrder';
import ApplyPromotionCodes from './ApplyPromotionCodes';
import { parseRichText } from 'helpers/contentful';
import { Typography, Alert, IconInfo } from '@newulife/common';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from 'state';
import { toast } from 'react-toastify';
import { useField } from 'hooks/useContentfulApi';
import useQuery from 'hooks/useQuery';
import { usePaymentSession } from 'hooks/useCreatePaymentSession';
import { isEmpty } from 'lodash';

export default function ReviewYourOrder() {
  const { createPaymentSession } = usePaymentSession();
  const [title] = useField('banner1Ref.fields.title');
  const [failedPayment] = useField('banner7Ref.fields.textString');
  const hasInitialized = useRef(false);
  const [form, setForm] = useRecoilState(formState);
  const {
    locale,
    countryInfo: { name: shippingCountry },
    orderId,
    userId,
    localeId,
    shipMethodId,
    onlineOrderId,
    PromoCodeEvaluations,
    authRep,
  } = form;
  const isRepLoggedIn = !isEmpty(authRep);
  const { showLoader, hideLoader } = useLoader();
  const {
    next,
    setNext,
    tip,
    setTip,
    error: appErrorObject,
    setError,
  } = useNextContextConsumer();
  const {
    state: { products, starterKit },
    parseProductsForOrder,
  } = useProducts();
  const {
    state: { subscriptions },
    parseSubscriptionsForOrder,
  } = useSubscriptions();
  const [methods, setMethods] = useState([]);
  const [promos, setPromoItems] = useState([]);
  const query = useQuery();

  useEffect(() => {
    setNext({ ...next, enabled: !isNull(shipMethodId), validate });
  }, [shipMethodId]);

  useEffect(() => {
    if (hasInitialized.current) return;
    hasInitialized.current = true;
    showLoader();
    getShippingMethods();
    updateCart();
    setTip({ ...tip, message: 'Please select a shipping method.' });
    hideLoader();
  }, []);

  const updateCart = async (newCartData = {}) => {
    showLoader();
    const payload = {
      onlineOrderId,
      localeId,
      shipMethodId: newCartData.shipMethodId || shipMethodId || (methods?.length === 1 ? methods[0].ID : null),
      ...(newCartData?.removedPromoCode
        ? {}
        : {
            promoCode:
              newCartData.promoCode ||
              PromoCodeEvaluations?.Evaluations?.map(
                (code) => code.PromotionCode,
              )?.[0] ||
              '',
          }),
      removedPromoCode: newCartData.removedPromoCode,
      products: products
        .map((product) => ({
          productId: product.ProductID,
          quantity: product.Quantity,
          cv: product.Volume,
          qv: product.Volume2,
          price: product.Price,
          isAutoship: false,
          isUpdate: true,
          orderId,
          country: shippingCountry,
        }))
        .concat(
          subscriptions.map((subscription) => ({
            productId: subscription.ProductID,
            quantity: subscription.Quantity,
            cv: subscription.Volume,
            qv: subscription.Volume2,
            price: subscription.Price,
            isAutoship: true,
            isUpdate: true,
            orderId,
            country: shippingCountry,
          })),
        ),
    };
    if (starterKit) {
      payload.products.push({
        productId: starterKit.ProductID,
        quantity: 1,
        cv: starterKit.Volume,
        qv: starterKit.Volume2,
        price: starterKit.Price,
        isAutoship: false,
        isUpdate: true,
        orderId,
        country: shippingCountry,
      });
    }
    if (!payload.shipMethodId) return;
    order
      .updateCart(payload)
      .then((res) => {
        if (res?.error) {
          showCustomError(res.error.message);
        } else {
          setForm({
            ...form,
            ...res,
            shipMethodId: payload.shipMethodId,
          });
          getShippingMethods();
        }
      })
      .catch((err) => {
        toast.error(
          'Could not update cart. If problem persists, please contact support',
          {
            toastId: 'update-cart',
            autoClose: false,
          },
        );
      })
      .finally(() => {
        hideLoader();
      });
  };

  function showCustomError(string) {
    if (typeof string === 'string')
      setError({ ...appErrorObject, enabled: true, message: string });
  }

  const getShippingMethods = () => {
    shipping
      .get({
        orderId,
        shippingCountry,
        locale,
      })
      .then((shippingMethods) => {
        setMethods(shippingMethods);
      })
      .catch(() => {
        showCustomError('Server problem please try again');
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Validate if the method has already been selected.
  const setFormShipMethod = (id) => {
    if (shipMethodId !== id) updateCart({ shipMethodId: id });
  };

  // Validate before heading to payment page.
  const validate = async () => {
    if (orderId && userId) {
      try {
        // Tag subscription items for autoship from review to payment.
        await subscription.addToAutoship(orderId);

        // Update totals for our own DB.
        await order.updateTotals(orderId);

        return isRepLoggedIn ? {} : await createPaymentSession();
      } catch (err) {
        toast.error(
          typeof err === 'string'
            ? err
            : err?.error ||
                'Order could not be validated. If the problem persists, please contact support.',
          { toastId: 'validate', autoClose: false },
        );
        return false;
      }
    }
  };

  if (methods.length === 0) return null;

  return (
    <>
      <section className="intro-text center">
        <Typography variant="header1">{title}</Typography>
      </section>
      {query.get('failedPayment') && (
        <Alert
          severity="error"
          variant="standard"
          sx={{
            mx: 'auto',
            mb: '40px',
          }}
        >
          <Typography as="p" variant="body2" color="inherit">
            {failedPayment}
          </Typography>
        </Alert>
      )}
      <SelectShippingMethod
        methods={methods}
        validateMethod={setFormShipMethod}
      />
      <ApplyPromotionCodes onUpdateCart={updateCart} />
      <ProductOrder promos={promos} onUpdateCart={updateCart} />
      <SubscriptionOrder onUpdateCart={updateCart} />
      <Disclaimer />
    </>
  );
}

const Disclaimer = () => {
  const [disclaimer] = useField('banner5Ref');
  const { listArray } = disclaimer?.fields;
  const {
    businessMarket: { englishName: country },
  } = useRecoilValue(formState);
  if (!listArray?.length) return null;

  const item = listArray.find(({ fields: { descriptiveTitle: title } }) =>
    title.includes(country),
  );

  const richText = item?.fields?.text1 || listArray?.[0]?.fields?.text1;
  return (
    <section className="center">
      <span className="text-center wide-desktop">
        {parseRichText(richText)}
      </span>
    </section>
  );
};
