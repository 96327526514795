import { post, get } from './request';
import product from './product';
import defaultLanguage from 'constants/defaultLanguage';
import { toast } from 'react-toastify';

const user = {
  get: (id) => get(`/api/getRepId/:${id}`),
  getByGuid: (guid) => {
    return post(`/api/getInfoForEnrollmentPage`, { guid }).then((userData) => {
      return userData === 'Invalid guid' || userData === 'Invalid GUID' 
        ? userData 
        : userData?.[0];
    });
  },
  getLanguageCode: (id) => get(`/api/GetLanguageCode/:${id}`),
  getLanguageById: (localeId) =>
    get(`/api/getLanguageFromLocale/:${localeId}`).then(
      (response) => {
        if (!response || response.length === 0) 
          toast.warn('Language not found - defaulting to English', { toastId: 'lang-warn' })
        return response?.length > 0 ? response[0] : defaultLanguage
      }
    ),
  getMoneyInfo: (country) => get(`/api/getMoneyInfo/:${country}`),
  enroll: (data) => {
    const { lang, country } = data;
    return product.getLocaleId(country, lang).then((localeId) => {
      return Promise.reject();
    });
  },
  getJapanBankInfo: (bankId) => {
    if (!bankId) return Promise.resolve('');
    return get(`/api/getJapanBankName/${bankId}`).then(
      ({ katakana: bank = '' } = {}) => bank,
    );
  },
  getJapanBranchInfo: (bankId, branchId) => {
    if (!bankId || !branchId) return Promise.resolve('');
    return get(`/api/getJapanBranchName/${bankId}/${branchId}`).then(
      ({ katakana: branch = '' } = {}) => branch,
    );
  },
  mock: (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(777);
      }, 1000);
    });
  },
};

export default user;
